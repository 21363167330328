export const PACKAGES = [
  {
    title: 'Enterprise',
    description: 'Kick your attraction’s souvenir service off with a bang!',
    subtitle: 'Set Pricing & Features',
    id: 1,
    options: [
      'Free sign up',
      'Premium support',
      '500 free codes once off',
      'Full access to all features',
      'DSLRS compatible on request (Coming soon)',
      'Join the HSL Revenue Share programme',
    ],
  },
  {
    title: 'Pay As You Go',
    description: 'Dynamite comes in small packages; this one is no different.',
    subtitle: 'Custom Package',
    id: 3,
    options: [
      'Free sign up',
      'Premium support',
      '500 free codes once off',
      'Full access to all features',
      'Get QR codes for 60 cents (USD) for a limited time offer',
      'DSLRS compatible on request (Coming soon)',
    ],
  },
  // Premium package is not available for now (HP-725)
  // {
  //   title: 'Premium',
  //   id: 2,
  //   options: [
  //     '$50 per month',
  //     '100 Free QR codes per month',
  //     'Full access To all features',
  //     'DSLRS compatible on request',
  //     'Premium support',
  //   ],
  // },
];
