import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router';
import { useStore } from '../stores/store';

// @ts-ignore
interface Props extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

export default function PrivateRoute({ component: Component, ...rest }: Props) {
  const { userStore } = useStore();
  const { isLoggedIn, user } = userStore;

  return (
      <Route
          {...rest}
          render={(props) =>
              // @ts-ignore
              isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />
      } //if not logged in return to the home page
    />
  );
}
