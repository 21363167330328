export interface TitleLink {
  title: string;
  link: string;
}

export const TopNavLinks: { [key: string]: TitleLink[] } = {
  codes: [
    { title: 'Summary', link: '/codes' },
    { title: 'Bundles', link: '/codes/bundles' },
    { title: 'History', link: '/codes/history' },
  ],
  settings: [
    { title: 'Summary', link: '/codes' },
    { title: 'Bundles', link: '/codes/bundles' },
    { title: 'History', link: '/codes/history' },
  ],
  addProject: [
    { title: 'Summary', link: '/projects/summary' },
    { title: 'Templates', link: '/projects/:id/templates' },
    { title: 'Website', link: '/projects/website' },
    { title: 'Pop-up', link: '/projects/popup' },
    { title: 'Settings', link: '/projects/settings' },
  ],
};
