import { createContext, useContext } from 'react';
import CommonStore from './commonStore';
import { CurrencyStore } from './currenciesStore';
import FabricStore from './fabricStore';
import ModalStore from './modalStore';
import NavigationStore from './navigationStore';
import { ProjectStore } from './projectStore';
import { TemplatesStore } from './templatesStore';
import { AddMemberStore } from './addMemberStore';
import { AccountStore } from './accountStore';
import { AddPaymentStore } from './addPaymentStore';
import UserStore from './userStore';
import { SalesStore } from './salesStore';
import { SubscriptionsStore } from './subscriptionsStore';
import { TeamStore } from './teamStore';
import { QrCodeStore } from './qrCodeStore';
import { DashboardStore } from './dashboardStore';
import { PaypalStore } from './paypalStore';
import { StripeStore } from './stripeStore';
import { PaygateStore } from './paygateStore';

interface Store {
  commonStore: CommonStore;
  userStore: UserStore;
  modalStore: ModalStore;
  navigationStore: NavigationStore;
  projectStore: ProjectStore;
  templatesStore: TemplatesStore;
  addMemberStore: AddMemberStore;
  accountStore: AccountStore;
  addPaymentStore: AddPaymentStore;
  currencyStore: CurrencyStore;
  fabricStore: FabricStore;
  salesStore: SalesStore;
  subscriptionsStore: SubscriptionsStore;
  teamStore: TeamStore;
  qrCodeStore: QrCodeStore;
  dashboardStore: DashboardStore;
  paypalStore: PaypalStore;
  stripeStore: StripeStore;
  paygateStore: PaygateStore;
}
export const store: Store = {
  commonStore: new CommonStore(),
  userStore: new UserStore(),
  modalStore: new ModalStore(),
  navigationStore: new NavigationStore(),
  projectStore: new ProjectStore(),
  templatesStore: new TemplatesStore(),
  addMemberStore: new AddMemberStore(),
  accountStore: new AccountStore(),
  addPaymentStore: new AddPaymentStore(),
  currencyStore: new CurrencyStore(),
  fabricStore: new FabricStore(),
  salesStore: new SalesStore(),
  subscriptionsStore: new SubscriptionsStore(),
  teamStore: new TeamStore(),
  qrCodeStore: new QrCodeStore(),
  dashboardStore: new DashboardStore(),
  paypalStore: new PaypalStore(),
  stripeStore: new StripeStore(),
  paygateStore: new PaygateStore(),
};

//make this context availible in react app world
//store contains the activity store which contains our observables
export const StoreContext = createContext(store); //from react

//react hook to use the store in the components
//hook contains the store context
export function useStore() {
  return useContext(StoreContext);
}
