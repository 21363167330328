import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Col, Layout, Modal, Row } from 'antd';
import WebsitePreview from './WebsitePreview';
import WebsiteSettings from './WebsiteSettings';
import { useStore } from '../../../../app/stores/store';
import { Link, useLocation } from 'react-router-dom';
import { EntityStatus } from '../../../../app/common/options/enums';
import ProjectValidationModal from '../../../projects/components/ProjectValidationModal';

function TemplateWebsite() {
  const { templatesStore, projectStore, navigationStore } = useStore();
  const {
    templateWebsite,
    submittingWebsite,

    updateTemplateWebsite,
    setTemplateWebsite,
  } = templatesStore;
  const { selectedProject, changeMainValue } = projectStore;
  const location = useLocation();

  function save() {
    if (!templateWebsite) return;

    updateTemplateWebsite(templateWebsite);
  }

  function removeWebsite() {
    if (!templateWebsite || !selectedProject) return;

    if (selectedProject.status === EntityStatus.Active) {
      Modal.info({
        content: (
          <ProjectValidationModal
            issues={[]}
            message="Please deactivate the project before trying to remove the website."
          />
        ),
        className: 'project-validation-modal',
      });
      return;
    }

    updateTemplateWebsite(
      {
        ...templateWebsite,
        isMain: false,
      },
      selectedProject.id.toString()
    );
    changeMainValue(templateWebsite.templateId!, 'website', false);
    setTemplateWebsite(null);
  }

  const { Header, Content } = Layout;
  return (
    <>
      <Layout className="project-website">
        <Header>
          <h3 className="tab-header">Website Preview</h3>
          {location.pathname.includes('projects') && (
            <div
              style={{
                display: 'flex',
                height: 'fit-content',
                marginLeft: 'auto',
              }}>
              <div
                style={{
                  height: 'fit-content',
                  margin: 'auto 10px',
                  lineHeight: '32px',
                }}>
                <Link
                  to={`${navigationStore.baseUrl}/templates/${templateWebsite?.templateId}?tab=2`}>
                  Click here
                </Link>
                , to edit the website.
              </div>
              <Button
                loading={submittingWebsite}
                style={{ marginRight: '20px' }}
                type="primary"
                danger
                onClick={removeWebsite}>
                Remove from project
              </Button>
            </div>
          )}
          {!location.pathname.includes('projects') && (
            <Button loading={submittingWebsite} type="primary" onClick={save}>
              Save
            </Button>
          )}
        </Header>

        <Content className="project-website-content">
          <Row>
            <Col span={16}>
              <WebsitePreview />
            </Col>

            <Col span={8}>
              <WebsiteSettings />
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}

export default observer(TemplateWebsite);
