import React, { useEffect } from 'react';
import { Button, Layout } from 'antd';
import EmptyList from '../../app/layout/EmptyList';
import { useStore } from '../../app/stores/store';
import AddTeamMember from './components/AddTeamMember';
import { observer } from 'mobx-react-lite';
import TeamMemberList from './components/TeamMemberList';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { getActiveSubscriptionId } from '../../app/common/util/common';

function Team() {
  const { subscriptionsStore, modalStore, userStore } = useStore();
  const {
    loading,

    getUsers,
    subscriptionUsers,
  } = subscriptionsStore;
  const { openModal } = modalStore;
  const { user } = userStore;
  const activeSubscriptionId = getActiveSubscriptionId();

  useEffect(() => {
    if (!user || !activeSubscriptionId) return;

    getUsers(activeSubscriptionId);
  }, [getUsers, activeSubscriptionId]);

  function openAddTeamMember() {
    openModal(true, <AddTeamMember />, 'Add New Team Member', true);
  }

  if (loading) return <LoadingComponent content={`Loading team members...`} />;

  const { Header, Content } = Layout;
  return (
    <Layout className="team">
      <Header className={'view-project-header'}>
        <div>
          <h2>Team</h2>

          <Button
            type={'primary'}
            className="team-add-member-button"
            onClick={openAddTeamMember}>
            Add Team Member
          </Button>
        </div>
      </Header>

      <Content>
        {subscriptionUsers.length > 0 ? (
          <TeamMemberList />
        ) : (
          <EmptyList
            message="There are no team members to show. Click below to add one."
            buttonText="Add Team Member"
            buttonAction={openAddTeamMember}
            customImage="/assets/images/people-list.svg"
          />
        )}
      </Content>
    </Layout>
  );
}

export default observer(Team);
