import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { PACKAGES } from '../../user/packages';
import { useStore } from '../../../app/stores/store';
import DeleteConfirmationModal from '../../../app/common/modals/DeleteConfirmationModal';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import ActivateSubscription from './ActivateSubscription';
import { navigate } from '../../../app/common/util/common';

function Subscription() {
  const {
    accountStore,
    modalStore,
    subscriptionsStore,
    stripeStore,
    userStore,
  } = useStore();
  const { selectedUserDetails } = accountStore;
  const { openModal, closeModal } = modalStore;
  const { changeSubscription, requestSubscriptionPackageChange } =
    subscriptionsStore;
  const { cancelSubscription } = stripeStore;
  const { user } = userStore;

  const [mode, setMode] = useState('viewing');
  function showModal(id: number) {
    if (!selectedUserDetails) return;
    if (id === selectedUserDetails.subscriptionPackage) return;

    let message = (
      <div>
        <p>
          You are about to upgrade to the{' '}
          {id === 1 ? 'enterprise' : 'Pay as You Go'} package!
        </p>
        <p>
          <strong>
            We will help you upgrade your account and will be in touch shortly.
          </strong>
        </p>
        <p>Please confirm you want to request an upgrade to continue.</p>
      </div>
    );

    openModal(
      true,
      <DeleteConfirmationModal
        title="Subscription Confirmation!"
        cancel={closeModal}
        confirm={() => confirmChange(id)}
        message={message}
        cancelText="No"
        confirmText="Yes"
      />,
      'Changing Package',
      true,
      'delete-confirmation'
    );
  }

  async function confirmChange(id: number) {
    closeModal();

    // Activates a form to enter details
    if (id === 2) {
      setMode('activate');
      return;
    }

    // Note: this self change feature has been removed, while we implement revenue share (HP-725)
    // toast.info('Requesting upgrade...');
    // if (selectedUserDetails!.subscriptionPackage === 2) {
    //   await cancelSubscription(user!.subscriptionId);
    // }

    // Request upgrade
    // await changeSubscription(id); // Note: this self change feature has been removed, while we implement revenue share (HP-725)
    await requestSubscriptionPackageChange(id);
    toast.info('Requested upgrade! We will be in touch shortly.');
    // navigate('/account?tab=2');
    // window.location.reload();
  }

  return (
    <>
      <h3 className="tab-header" style={{ marginBottom: '20px' }}>
        Subscriptions
      </h3>
      <div className="subscription">
        {mode === 'activate' ? (
          <ActivateSubscription cancel={() => setMode('viewing')} />
        ) : (
          <>
            <h4>
              Please click on one of the options below to change or upgrade your
              subscription package:
            </h4>
            <div className="packages-list">
              <div className="packages-list-content">
                {PACKAGES.map((p, i) => (
                  <div key={i} className="account-package-container">
                    <div className="selected-package">
                      {selectedUserDetails?.subscriptionPackage === p.id && (
                        <h4>Current Package</h4>
                      )}
                    </div>
                    <div
                      className={`account-list pl-package ${
                        i % 2 !== 0 ? 'orange' : ''
                      }`}
                      onClick={() => showModal(p.id)}>
                      <div className="pl-package-header">
                        <div className="pl-package-header-inner">
                          {p.subtitle}
                        </div>
                      </div>
                      <div className="pl-package-body">
                        <div className="pl-package-body-inner">
                          <h3 className="pl-package-body-tile">{p.title}</h3>
                          <p className="pl-package-body-description">
                            {p.description}
                          </p>
                          {selectedUserDetails?.subscriptionPackage !==
                            p.id && (
                            <Button
                              type="primary"
                              className={`select-button ${
                                i % 2 !== 0 ? 'orange' : ''
                              }`}>
                              Request Upgrade
                            </Button>
                          )}
                        </div>
                        <ul className="pl-package-option-container">
                          {p.options.map((o, index) => (
                            <li
                              key={`${i}-${index}-option`}
                              className={`pl-package-option ${
                                index === 0 ? 'no-border' : ''
                              }`}>
                              {o}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default observer(Subscription);
