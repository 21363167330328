import { makeAutoObservable, runInAction } from 'mobx';
import { PaymentGateway } from '../models/paymentGateway';
import agent from '../api/Agent';
import { TemplateCurrencyDTO } from '../models/templates/templateCurrencyDTO';
import { SalesTransactionsFull } from '../models/sales/salesTransactionsFull';
import { TransactionsFilter } from '../models/sales/transactionsFilter';
import { yyyymmdd } from '../common/util/date';
import { PaymentGatewayPost } from '../models/sales/paymentGatewayPost.dto';
import { AxiosError } from 'axios';
import Utf8 from 'crypto-js/enc-utf8';
import Hex from 'crypto-js/enc-hex';
import DES from 'crypto-js/tripledes';
import { toast } from 'react-toastify';
import { getSubscriptionIdFromUrl } from 'src/app/common/util/common';

export class SalesStore {
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  /**
   *
   * Subscription Currencies
   *
   */
  loadingCurrencies: boolean = false;
  subscriptionCurrencies: TemplateCurrencyDTO[] = [];

  setLoadingCurrencies = (value: boolean) => {
    this.loadingCurrencies = value;
  };

  fetchSubscriptionCurrencies = async (id: number) => {
    try {
      this.setLoadingCurrencies(true);

      const result = await agent.Currencies.subscriptionCurrencies(id);
      this.setSubscriptionCurrencies(result.subscriptionCurrencyDtos);
    } catch (error) {
      throw error;
    } finally {
      this.setLoadingCurrencies(false);
    }
  };

  setSubscriptionCurrencies = (values: TemplateCurrencyDTO[]) => {
    this.subscriptionCurrencies = values;
  };

  /**
   * ============================
   * Sales Stats and transactions
   * ============================
   */
  loadingTransactions: boolean = false;
  loadingStats: boolean = false;
  transactions: SalesTransactionsFull | null = null;
  selectedFilter: TransactionsFilter = {
    currency: 'All',
    endDate: yyyymmdd(),
    guidSearch: null,
    paymentProvider: 'All',
    saleType: -1,
    startDate: '20190101',
    status: -1,
    subscriptionId: getSubscriptionIdFromUrl(window.location.pathname) ?? -1,
    templateId: -1,
    albumCode: null,
  };
  firstLoad: boolean = true;

  setSelectedFilter = (value: TransactionsFilter) => {
    this.selectedFilter = value;
  };

  setLoadingTransactions = (value: boolean) => {
    this.loadingTransactions = value;
  };

  setFirstLoad = (value: boolean) => {
    this.firstLoad = value;
  };

  setTransactions = (value: SalesTransactionsFull | null) => {
    this.transactions = value;
  };

  fetchSalesData = async (subscriptionId: number) => {
    try {
      this.setLoading(true);

      this.selectedFilter.subscriptionId = subscriptionId;
      const result = await agent.Sales.transactions(this.selectedFilter);
      console.log(result);
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false);
    }
  };

  fetchTransactions = async (
    isSysAdmin: boolean,
    filter?: TransactionsFilter
  ) => {
    try {
      this.setLoadingTransactions(true);

      this.setTransactions(null);

      const result = isSysAdmin
        ? await agent.Sales.allTransactions(filter ?? this.selectedFilter)
        : await agent.Sales.transactions(filter ?? this.selectedFilter);
      this.setTransactions(result);
      if (this.firstLoad) {
        this.setFirstLoad(false);
      }
    } catch (error) {
      throw error;
    } finally {
      this.setLoadingTransactions(false);
    }
  };

  /**
   *
   * Payment Gateways
   *
   */

  submittingGateway: boolean = false;
  loadingGateways: boolean = false;
  gatewayRegistry = new Map<number, PaymentGateway>();
  selectedGateway: PaymentGateway | null = null;
  currentGateways: string[] = [];

  setSubmittingGateway = (value: boolean) => {
    this.submittingGateway = value;
  };

  setLoadingGateways = (value: boolean) => {
    this.loadingGateways = value;
  };

  createPaymentGateway = async (dto: PaymentGatewayPost) => {
    try {
      this.setSubmittingGateway(true);

      const result = await agent.Sales.createGateway(dto);
      this.pushGateway(result);
    } catch (error) {
      throw error;
    } finally {
      this.setSubmittingGateway(false);
    }
  };

  private pushGateway = (value: PaymentGateway) => {
    const cryptkey = Utf8.parse(process.env.REACT_APP_SECRET_KEY ?? '');
    const ivv = Hex.parse('00000000000000000000000000000000');
    const dec = DES.decrypt(value.clientKey, cryptkey, {
      iv: ivv,
    });
    value.clientKey = dec.toString(Utf8);

    this.gatewayRegistry.set(value.id, value);
    this.currentGateways.push(value.gatewayName);
  };

  fetchPaymentGateways = async (id: number) => {
    try {
      this.setLoadingGateways(true);

      const result = await agent.Sales.listGateways(id);
      result.forEach((gateway: PaymentGateway) => {
        this.pushGateway(gateway);
      });
    } catch (error) {
      throw error;
    } finally {
      this.setLoadingGateways(false);
    }
  };

  get paymentGateways() {
    return Array.from(this.gatewayRegistry.values());
  }

  deleteGateway = async (id: number) => {
    try {
      this.setSubmittingGateway(true);

      await agent.Sales.deleteGateway(id);
      this.removeGateway(id);
      return { isSuccess: true };
    } catch (error) {
      const e = error as AxiosError;
      const { data } = e.response!;
      if (e.response && data) {
        return data;
      }
      throw error;
    } finally {
      this.setSubmittingGateway(false);
    }
  };

  private removeGateway = (id: number) => {
    const gateway = this.gatewayRegistry.get(id);
    this.gatewayRegistry.delete(id);

    if (gateway) {
      const index = this.currentGateways.findIndex(
        (s: string) => s === gateway.gatewayName
      );
      this.currentGateways.splice(index, 1);
    }
  };

  setSelectedGateway = (value: PaymentGateway | null) => {
    this.selectedGateway = value;
  };

  editGateway = async (dto: PaymentGatewayPost) => {
    try {
      this.setSubmittingGateway(true);
      const orignalKey = dto.clientKey;

      const cryptkey = Utf8.parse(process.env.REACT_APP_SECRET_KEY ?? '');
      const iv = Hex.parse('00000000000000000000000000000000');
      const enc = DES.encrypt(dto.clientKey, cryptkey, {
        iv,
      });
      dto.clientKey = enc.toString();
      dto.userName = `${dto.firtsName} ${dto.lastName}`;
      await agent.Sales.editGateway(dto.id, dto);
      runInAction(() => {
        const gateway = this.gatewayRegistry.get(dto.id);
        if (!gateway) return;

        gateway.firtsName = dto.firtsName;
        gateway.lastName = dto.lastName;
        gateway.email = dto.email;
        gateway.clientKey = orignalKey;
        gateway.clientId = dto.clientId;
      });
      toast.success('Payment gateway saved!');
    } catch (error) {
      throw error;
    } finally {
      this.setSubmittingGateway(false);
    }
  };
}
