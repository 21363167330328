import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../app/stores/store';
import useQuery from '../../app/common/util/hooks';
import { Button, Col, Layout, Row } from 'antd';
import { PaymentErrors } from '../../app/common/options/paymentErrors';
import { history } from '../../index';

interface PageDetails {
  gateway: string;
  token: string;
  fail_reason: string;
  fail_message: string;
}

function PaymentFailed() {
  const { paypalStore } = useStore();
  const { setPaying } = paypalStore;

  const [details, setDetails] = useState<PageDetails>({
    gateway: '',
    token: '',
    fail_reason: '',
    fail_message: '',
  });

  const query = useQuery();
  useEffect(() => {
    const index = Object.keys(PaymentErrors).findIndex(
      (s) => s === query.get('gateway') ?? ''
    );
    const error = Object.values(PaymentErrors)[index].find(
      (e) => e.code === query.get('errorcode') ?? ''
    );

    setDetails({
      gateway: query.get('gateway') ?? '',
      token: query.get('token') ?? '',
      fail_reason: query.get('errorcode') ?? '',
      fail_message: error ? error.message : '',
    });
    setPaying(true);
  }, [setDetails]);

  function back() {
    history.push(`/codes`);
    localStorage.setItem('payment_made', 'failure');
  }

  const { Header, Content } = Layout;
  return (
    <>
      <Layout>
        <Header className="view-project-header">
          <div>
            <h2>Failed Payment</h2>
          </div>
        </Header>

        <Content className="payment-success">
          {details.fail_reason.trim() === '' ? (
            <div className="payment-cancelled">
              The transaction was cancelled manually by the user, please click
              below to go back.
            </div>
          ) : (
            <>
              <div>The payment failed due to the following: </div>
              <div className="payment-success-details">
                <Row>
                  <Col span={7}>
                    <strong>Error code:</strong>
                  </Col>
                  <Col span={12}>{details.fail_reason}</Col>
                </Row>

                <Row>
                  <Col span={7}></Col>
                  <Col span={17}>{details.fail_message}</Col>
                </Row>
              </div>
            </>
          )}

          <Button type="primary" onClick={back}>
            Go back
          </Button>
        </Content>
      </Layout>
    </>
  );
}

export default observer(PaymentFailed);
