import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Card, Select, Table } from 'antd';
import { TimePeriods } from '../../../app/common/options/selectOptions';
import { Bar, Doughnut } from 'react-chartjs-2';
import { useStore } from '../../../app/stores/store';
import EmptyList from '../../../app/layout/EmptyList';
import { BarChartData } from '../../../app/models/dashboard/barChartData';
import { PhotographerDetailsDto } from '../../../app/models/dashboard/photographerDetails.dto';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import Avatar from './Avatar';

interface Props {
  title: string;
  type: string;
  data: any;
  filterTime: (e: string, name: string) => void;
  name: string;
}

function StatsCard(props: Props) {
  const { title, type, data, filterTime, name } = props;

  const { dashboardStore } = useStore();
  const { loading, loadingComponent } = dashboardStore;

  const [body, setBody] = useState(<></>);

  useEffect(() => {
    switch (type) {
      case 'pie':
        buildPie();
        break;

      case 'table':
        buildTable();
        break;

      case 'bar':
        buildBar();
        break;
    }
  }, [data]);

  function buildPie() {
    const d = {
      labels: ['Download', 'Whatsapp', 'Twitter', 'Facebook'],
      datasets: [
        {
          label: '# of Shares',
          data,
          backgroundColor: [
            '#fbba00', // Download
            '#25D366', // Whatsapp
            '#1DA1F2', // Twitter
            '#4267B2', // Facebook
          ],
          borderWidth: 0,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      cutout: 80,
      plugins: {
        legend: {
          position: 'right' as const,
          fullSize: false,
          labels: {
            boxWidth: 17,
            boxHeight: 17,
            font: {
              size: 14,
            },
          },
        },
      },
    };

    const chart = (
      <>
        <div className="doughnut-container">
          <Doughnut options={options} data={d} />
        </div>
      </>
    );
    setBody(chart);
  }

  function buildTable() {
    const columns = [
      {
        title: 'PHOTOGRAPHER NAME',
        dataIndex: 'userName',
        key: 'userName',
        render: (userName: string, record: any, index: number) => (
          <div className="photographer-table-name">
            <Avatar username={userName} index={index} allowTooltip={false} />
            <p>{userName}</p>
          </div>
        ),
      },
      {
        title: 'PROJECTS',
        dataIndex: 'projects',
        key: 'projects',
      },
      {
        title: 'PHOTOS',
        dataIndex: 'totalNoOfPhotos',
        key: 'totalNoOfPhotos',
      },
      {
        title: 'SHARES',
        dataIndex: 'shares',
        key: 'shares',
      },
    ];

    // const dataSource = data;
    const table = (
      <Table
        className="photographer-table"
        columns={columns}
        dataSource={data.slice(0, 4)}
        rowKey={(record: PhotographerDetailsDto, index?: number) => {
          return `${record.userId}-${index}`;
        }}
        pagination={false}
      />
    );
    setBody(table);
  }

  function buildBar() {
    const labels = data.slice(0, 4).map((d: BarChartData) => d.name);
    const chartData = data.slice(0, 4).map((d: BarChartData) => d.value);

    const d = {
      labels,
      datasets: [
        {
          data: chartData,
          backgroundColor: ['#1c8bff', '#f39200', '#457FC1', '#fbba00'],
          borderWidth: 0,
          borderRadius: 7,
          maxBarThickness: 40,
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          position: 'nearest' as const,
        },
      },
      scales: {
        y: {
          title: {
            text: 'Photos Taken',
            display: true,
          },
          grid: {
            drawBorder: false,
          },
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            callback: function (value: any): string {
              // @ts-ignore
              const label = this.getLabelForValue(value);
              return label.length > 15 ? `${label.substring(0, 14)}...` : label;
            },
          },
        },
      },
    };

    const chart = (
      <div className="bar-container">
        <Bar data={d} options={options} />
      </div>
    );

    setBody(chart);
  }

  const { Option } = Select;

  return (
    <>
      <Card className="stats-card">
        <div className="stats-card-header">
          <h3>{title}</h3>

          <Select
            defaultValue={'all'}
            onChange={(e: string) => filterTime(e, name)}
            disabled={loading && loadingComponent.includes(name)}
            loading={loading && loadingComponent.includes(name)}>
            {TimePeriods.map((v) => (
              <Option value={v.value} key={v.value}>
                {v.label}
              </Option>
            ))}
          </Select>
        </div>

        {loading && loadingComponent.includes(name) ? (
          <LoadingComponent content={`Loading ${title}...`} />
        ) : (
          <>
            {!data || data.length === 0 ? (
              <EmptyList
                message="No stats for this time period"
                hideButton={true}
                buttonAction={() => {}}
                buttonText=""
              />
            ) : (
              body
            )}
          </>
        )}
      </Card>
    </>
  );
}

export default observer(StatsCard);
