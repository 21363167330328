import React from 'react';
import { Layout, Card, Form, Input, Button } from 'antd';
import { history } from '../../..';
import { useStore } from '../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';

function EnterEmail() {
  const { accountStore } = useStore();
  const { requestReset, submitting } = accountStore;

  async function onFinish(values: any) {
    const dto = {
      email: values.email,
    };

    await requestReset(dto);
    toast.success('Password request has been sent, please check your email.');
    history.push('/login');
  }

  return (
    <>
      <Layout className="forgot-password">
        <Card className="forgot-password-card">
          <p>
            Please enter your email address so we can send you a link to reset
            your password.
          </p>

          <Form
            name="enterEmail"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            requiredMark="optional">
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Please enter email address' },
              ]}>
              <Input placeholder="Enter email" />
            </Form.Item>

            <Button type="primary" htmlType="submit" loading={submitting}>
              Send email
            </Button>

            <Button
              href="/login"
              style={{ marginLeft: '20px' }}
              loading={submitting}>
              Cancel
            </Button>
          </Form>
        </Card>
      </Layout>
    </>
  );
}

export default observer(EnterEmail);
