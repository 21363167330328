import React from 'react';
import { Checkbox, Form, Input, Select } from 'antd';
import { useStore } from '../../../../app/stores/store';
import { observer } from 'mobx-react-lite';

function PopupSettings() {
  const { projectStore, templatesStore } = useStore();
  const { readonlySettings } = projectStore;
  const {
    selectedDataCollection,

    setDataCollection,
  } = templatesStore;

  const [form] = Form.useForm();
  function updateSettings() {
    setDataCollection({
      ...selectedDataCollection,
      collectionPoint: form.getFieldValue('collection_point'),
      collectionFrequencyTillCollected: form.getFieldValue('frequency'),
      collectionEnforcing: form.getFieldValue('force_submit') ? 1 : 0,
      termsAcceptMode: form.getFieldValue('terms_mode'),
    });
  }

  const { Option } = Select;
  return (
    <>
      <div className="website-header-subheading">Collection Settings</div>

      <Form
        form={form}
        initialValues={{
          collection_point: selectedDataCollection?.collectionPoint,
          frequency: selectedDataCollection?.collectionFrequencyTillCollected,
          force_submit: selectedDataCollection?.collectionEnforcing === 1,
          terms_mode: selectedDataCollection?.termsAcceptMode,
        }}
        onBlur={updateSettings}
        onValuesChange={updateSettings}
        labelAlign="left"
        className="popup-settings-form"
        name="popup-settings"
        layout="horizontal"
        labelCol={{ span: 10 }}>
        <Form.Item label="Collection Point:" name="collection_point">
          <Select
            getPopupContainer={(trigger) => trigger.parentElement}
            disabled={readonlySettings}>
            <Option value={0}>On Page Load</Option>
            <Option value={1}>On Download/Share</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Frequency: ">
          <div className="popup-settings-form-collection">
            <div>Collect every </div>
            <Form.Item name="frequency">
              <Input
                type="number"
                placeholder="0"
                style={{ width: '40px' }}
                readOnly={readonlySettings}
              />
            </Form.Item>
            <div>visits/shares until collected.</div>
          </div>
        </Form.Item>

        <Form.Item
          valuePropName="checked"
          label="Force Submit: "
          name="force_submit">
          <Checkbox disabled={readonlySettings} />
        </Form.Item>

        <Form.Item
          label="Terms Accept Mode:"
          name="terms_mode"
          className="popup-settings-form-mode">
          <Select
            getPopupContainer={(trigger) => trigger.parentElement}
            disabled={readonlySettings}>
            <Option value={1}>Implied By Pressing Submit</Option>
            <Option value={0}>Compulsory Checkbox</Option>
          </Select>
        </Form.Item>
      </Form>
    </>
  );
}

export default observer(PopupSettings);
