import { Layout, Card, Button } from 'antd';
import React from 'react';

function PasswordReset() {
  return (
    <>
      <Layout className="forgot-password">
        <Card className="forgot-password-card">
          <p>
            Your password has successfully been reset. Please click on the
            button to be taken back to the login page.
          </p>

          <Button href="/login">To Login</Button>
        </Card>
      </Layout>
    </>
  );
}

export default PasswordReset;
