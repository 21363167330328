import React from 'react';
import { Button, Checkbox, Input, Layout } from 'antd';
import { useStore } from '../../../../app/stores/store';
import { observer } from 'mobx-react-lite';

function PopupPreview() {
  const { projectStore, templatesStore } = useStore();
  const { selectedDataCollection } = templatesStore;
  const { selectedPopup } = projectStore;

  const { Header, Content } = Layout;
  return (
    <>
      <Layout className="popup-preview">
        {!selectedDataCollection?.isActive && (
          <Header>
            <div className="popup-preview-collecting">
              Data is currently not being collected for this template.
            </div>
          </Header>
        )}

        <Content className="popup-view">
          <div className="popup-view-preview">
            <div
              style={{
                backgroundColor:
                  selectedDataCollection?.headerFooterBackgroundColor,
              }}
              className="popup-view-preview-header">
              <h2
                style={{
                  color: selectedDataCollection?.headerFooterTextColor,
                }}>
                {selectedDataCollection?.headerText}
              </h2>
              <p
                style={{
                  color: selectedDataCollection?.headerFooterTextColor,
                }}>
                {selectedDataCollection?.bodyText}
              </p>
            </div>
            <div
              className="popup-view-preview-form"
              style={{
                backgroundColor: selectedDataCollection?.bodyBackgroundColor,
              }}>
              {selectedDataCollection?.collectName && (
                <Input placeholder={'Name'} />
              )}

              {selectedDataCollection?.collectEmail && (
                <Input placeholder={'Email'} />
              )}

              {selectedDataCollection?.collectPhone && (
                <Input placeholder={'Phone'} />
              )}

              <div className={'popup-view-preview-buttons'}>
                <Button
                  type="primary"
                  style={{
                    backgroundColor: selectedDataCollection?.submitButtonColor,
                    borderColor: selectedDataCollection?.submitButtonColor,
                    color: selectedDataCollection?.submitButtonTextColor,
                  }}>
                  {selectedDataCollection?.submitButtonText}
                </Button>

                {selectedDataCollection?.collectionEnforcing === 0 && (
                  <Button
                    type="primary"
                    style={{
                      borderColor: selectedDataCollection?.declineButtonColor,
                      backgroundColor:
                        selectedDataCollection?.declineButtonColor,
                      color: selectedDataCollection?.declineButtonTextColor,
                    }}>
                    {selectedDataCollection?.declineButtonText}
                  </Button>
                )}
              </div>
            </div>
            <div
              style={{
                backgroundColor:
                  selectedDataCollection?.headerFooterBackgroundColor,
              }}
              className="popup-view-preview-footer">
              {selectedDataCollection?.termsAcceptMode === 1 ? (
                <div
                  style={{
                    color: selectedDataCollection?.headerFooterTextColor,
                  }}>
                  By clicking Submit you agree to the terms and
                  conditions/privacy policy.
                </div>
              ) : (
                <Checkbox
                  style={{
                    color: selectedDataCollection?.headerFooterTextColor,
                  }}>
                  I have read and agree to the terms and conditions / privacy
                  policy.
                </Checkbox>
              )}
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
}
export default observer(PopupPreview);
