import { makeAutoObservable } from 'mobx';
import agent from '../api/Agent';
import { PaypalLinkItem } from '../models/paypal/paypalLinkItem';

export class PaypalStore {
  constructor() {
    makeAutoObservable(this);
  }

  submitting: boolean = false;
  executing: boolean = false;

  paying: boolean = false;

  setSubmitting = (value: boolean) => {
    this.submitting = value;
  };

  setExecuting = (value: boolean) => {
    this.executing = value;
  };

  setPaying = (value: boolean) => {
    this.paying = value;
  };

  create = async (amount: number, quantity: number) => {
    try {
      this.setSubmitting(true);
      const result = await agent.Paypal.create({
        amount: amount,
        quantity,
        returnUrl: `${
          process.env.REACT_APP_PAYMENT_SUCCESS_REDIRECT ?? ''
        }?gateway=paypal&amount=${amount}&${
          quantity == null ? '' : `count=${quantity}`
        }`,
        cancelUrl: `${
          process.env.REACT_APP_PAYMENT_FAIL_REDIRECT ?? ''
        }?gateway=paypal`,
        intent: 'sale',
        name: 'qrcode',
      });

      if (!result) {
        return;
      }

      const link: PaypalLinkItem | undefined = result.links.find(
        (l: PaypalLinkItem) => l.rel === 'approval_url'
      );
      if (link) {
        window.open(link.href, '_blank');
      }
    } catch (error) {
      throw error;
    } finally {
      this.setSubmitting(false);
    }
  };

  execute = async (paymentId: string, token: string, payerId: string) => {
    try {
      this.setExecuting(true);

      await agent.Paypal.execute({
        paymentId,
        token,
        payerId,
        name: 'qrcodes',
        gatewayName: 'paypal',
      });
    } catch (error) {
      throw error;
    } finally {
      this.setExecuting(false);
    }
  };
}
