import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import NoCodesStarter from './noCodesStarter';
import NoCodesPremium from './noCodesPremium';
import { Button, Space } from 'antd';
import { history } from '../../../index';

interface Props {
  value: {
    value: number;
    prices: {
      currency: string;
      price: number;
    }[];
    request: number;
  };
  cancel: () => void;
}

function NoCodes(props: Props) {
  const { value, cancel } = props;
  const { userStore, stripeStore } = useStore();
  const { user } = userStore;
  const { create: stripeCreate } = stripeStore;
  const [purchasing, setPurchasing] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(
    value.prices.find((p) => p.currency === 'USD') || value.prices[0]
  );

  function startPurchase() {
    if (!user) return;

    if (user.subscriptionPackageId === 1) {
      history.push('/account?tab=2');
      return;
    }

    setPurchasing(true);

    stripeCreate(
      selectedCurrency.price,
      'qrcode',
      value.request - +value.value,
      selectedCurrency.currency
    );
  }

  return (
    <>
      <Space direction="vertical">
        {user!.subscriptionPackageId === 1 ? (
          <NoCodesStarter />
        ) : user!.subscriptionPackageId === 2 ||
          user!.subscriptionPackageId === 3 ? (
          <NoCodesPremium
            prices={value.prices}
            selectedCurrency={selectedCurrency}
            onChangeSelectedCurrency={(value: {
              currency: string;
              price: number;
            }) => {
              setSelectedCurrency(value);
            }}
            total={value.value}
            request={value.request}
          />
        ) : (
          <></>
        )}
        <Space>
          <Button
            className="yellow-ant-btn"
            onClick={cancel}
            disabled={purchasing}>
            Cancel
          </Button>

          <Button type="primary" onClick={startPurchase} loading={purchasing}>
            {user!.subscriptionPackageId === 1
              ? 'Upgrade Package'
              : 'Purchase Codes'}
          </Button>
        </Space>
      </Space>
    </>
  );
}

export default observer(NoCodes);
