import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import { useStore } from '../../../app/stores/store';
import EmptyList from '../../../app/layout/EmptyList';
import { observer } from 'mobx-react-lite';
import { AlbumCodesListDataDto } from '../../../app/models/qrcodes/albumCodesListData.dto';
import DeleteConfirmationModal from '../../../app/common/modals/DeleteConfirmationModal';
import SafariMessage from '../../../app/common/modals/SafariMessage';

function QrCodesBundles() {
  const { qrCodeStore, modalStore, commonStore } = useStore();
  const {
    qrCodeSummary,
    downloadingCodes,

    downloadQrCode,
  } = qrCodeStore;
  const { isSafari } = commonStore;
  const { openModal, closeModal, downloadModal } = modalStore;

  function confirmDownload(id: number) {
    const message = (
      <p className="confirm-download">
        Please note: You can only download these QR codes once. Please make sure
        to keep them safe.
      </p>
    );

    openModal(
      true,
      <DeleteConfirmationModal
        title="Download confirmation!"
        cancel={closeModal}
        confirm={() => downloadCodes(id)}
        message={message}
        cancelText="Cancel"
        confirmText="Continue"
      />,
      'Changing Package',
      true,
      'delete-confirmation'
    );
  }

  async function downloadCodes(id: number) {
    closeModal();

    if (isSafari) downloadModal(<SafariMessage />);

    setDownloading(id);
    const code = await downloadQrCode(id);
    const links = code.linkToDownload.split(',');
    links.forEach((link: string) => {
      window.open(link, '_blank');
    });

    setDownloading(-1);
  }

  const [downloading, setDownloading] = useState(-1);
  const [datasource, setDatasource] = useState<AlbumCodesListDataDto[]>([]);

  useEffect(() => {
    if (!qrCodeSummary) return;

    setDatasource(
      qrCodeSummary.albumCodesRequestInfo.filter(
        (info: AlbumCodesListDataDto) => info.status === 'Completed'
      )
    );
  }, [qrCodeSummary]);

  const columns = [
    {
      title: 'Company Name',
      dataIndex: 'bundleName',
      key: 'bundleName',
      render: (bundleName: string, record: any) => (
        <div className="live-table-name">{bundleName}</div>
      ),
    },
    {
      title: 'Purchaser Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: '25vw',
    },
    {
      title: 'Total Codes',
      dataIndex: 'totalCodes',
      key: 'totalCodes',
      width: '9vw',
      render: (totalCodes: number) => <div>{totalCodes + 1}</div>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      // width: '12vw'
    },
    {
      title: '',
      key: 'more-actions',
      dataIndex: 'albumCodeRequestId',
      render: (albumCodeRequestId: number) => (
        <>
          <Button
            type="primary"
            loading={downloadingCodes && downloading === albumCodeRequestId}
            onClick={() => confirmDownload(albumCodeRequestId)}>
            Download
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="live-projects">
        {!qrCodeSummary || datasource.length < 1 ? (
          <EmptyList
            customImage="/assets/images/no-transactions.svg"
            message="There are no bundles"
            buttonAction={() => {}}
            buttonText=""
            hideButton={true}
          />
        ) : (
          <Table
            className="live-name"
            columns={columns}
            dataSource={datasource}
            rowKey={'albumCodeRequestId'}
          />
        )}
      </div>
    </>
  );
}

export default observer(QrCodesBundles);
