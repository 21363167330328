import React from 'react';
import { observer } from 'mobx-react-lite';
import { TemplateCurrencyDTO } from '../../../app/models/templates/templateCurrencyDTO';
import { Input, Radio, Switch } from 'antd';
import { useStore } from '../../../app/stores/store';

interface Props {
  currency: TemplateCurrencyDTO;
}

function CurrencyItem(props: Props) {
  const { currency } = props;
  const { currencyStore } = useStore();
  const { changeDefaultCurrency } = currencyStore;

  function fieldChanged(e: any, field: string) {
    switch (field) {
      case 'allowed':
        currency.allowCurrency = e;
        break;

      case 'single':
        currency.singleImagePrice = e.target.value;
        break;

      case 'set':
        currency.imageSetPrice = e.target.value;
        break;

      case 'default':
        currency.isDefault = e.target.checked;
        changeDefaultCurrency(currency.currencyId);
        break;
    }
  }

  return (
    <>
      <div className="currency-item">
        <Switch
          onChange={(e) => fieldChanged(e, 'allowed')}
          checked={currency.allowCurrency}
        />

        <div className="currency-item-title">
          <img src={`/assets/images/${currency.icon}`} alt="currency-icon" />
          <div className="currency-item-code">{currency.code}</div>
          <div className="currency-item-name">({currency.name})</div>
        </div>

        <Input
          onChange={(e) => fieldChanged(e, 'single')}
          type="number"
          value={currency.singleImagePrice}
        />
        <Input
          onChange={(e) => fieldChanged(e, 'set')}
          type="number"
          value={currency.imageSetPrice}
        />

        <Radio
          onChange={(e) => fieldChanged(e, 'default')}
          checked={currency.isDefault}
        />
      </div>
    </>
  );
}

export default observer(CurrencyItem);
