import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';
import { Switch } from 'react-router';
import SideBar from './SideBar';
import NavBar from './NavBar';
import Dashboard from '../../../features/dashboard/Dashboard';
import Projects from '../../../features/projects/Projects';
import Templates from '../../../features/templates/Templates';
import QrCodes from '../../../features/qrcodes/QrCodes';
import Team from '../../../features/team/Team';
import Account from '../../../features/account/Account';
import NotFound from '../../../features/errors/NotFound';
import ServerError from '../../../features/errors/ServerError';
import PrivateRoute from '../PrivateRoute';
import LoadingComponent from '../LoadingComponent';
import ViewProject from '../../../features/projects/ViewProject';
import Sales from '../../../features/sales/Sales';
import ActiveTeamMembers from '../../../features/team/ActiveTeamMembers';
import TeamMemberDetails from '../../../features/team/TeamMemberDetails';
import ViewTemplate from '../../../features/templates/ViewTemplate';
import { User } from '../../models/user';
import { history } from '../../../index';
import PaymentSuccess from '../../../features/payments/PaymentSuccess';
import PaymentFailed from '../../../features/payments/PaymentFailed';
import StripeRedirect from '../../../features/account/components/subscription/StripeRedirect';
import TutorialWalkthrough from '../../common/tutorialWalkthough/TutorialWalkthrough';
import Logger from '../../common/logger/Logger';
import TermsAndConditionsPopup from 'src/features/account/components/TermsAndConditionsPopup';

function DashboardRoutes() {
  const { Header, Sider, Content } = Layout;

  const {
    commonStore,
    userStore,
    dashboardStore,
    accountStore,
    subscriptionsStore,
  } = useStore();

  const { getOverall } = dashboardStore;

  const { getUser } = userStore;
  const { fetchDetails } = accountStore;
  const { fetch: fetchSubscriptionDetails } = subscriptionsStore;

  const {
    token,
    appLoaded,

    setAppLoaded,
  } = commonStore;

  useEffect(() => {
    if (token) {
      // todo: handle error if any of these fail
      Promise.all([
        getUser().then((user?: User) =>
          fetchSubscriptionDetails(Number(user?.subscriptionId))
        ),
        fetchDetails(),
      ]).finally(() => setAppLoaded());
    } else {
      history.push('/login');
      setAppLoaded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getUser,
    getOverall,
    fetchSubscriptionDetails,
    fetchDetails,
    setAppLoaded,
  ]);

  if (!appLoaded)
    return (
      <LoadingComponent content={'Loading Happy Snappy Lite Dashboard...'} />
    );

  return (
    <>
      <Layout className="app-layout">
        <Sider style={{ width: '230px', maxWidth: 0 }}>
          <SideBar />
        </Sider>

        <Layout>
          <Header style={{ zIndex: 1 }}>
            <NavBar />
          </Header>
          <Logger />
          <TutorialWalkthrough />
          <TermsAndConditionsPopup />

          <Content style={{ overflowY: 'auto' }}>
            <Switch>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/projects" component={Projects} />
              <PrivateRoute
                exact
                path="/projects/:id"
                component={ViewProject}
              />
              <PrivateRoute
                exact
                path={['/templates', '/projects/:id/templates']}
                component={Templates}
              />
              <PrivateRoute
                exact
                path={'/templates/:id'}
                component={ViewTemplate}
              />
              <PrivateRoute exact path="/codes" component={QrCodes} />
              <PrivateRoute
                exact
                path="/payment/success"
                component={PaymentSuccess}
              />
              <PrivateRoute
                exact
                path="/payment/fail"
                component={PaymentFailed}
              />
              <PrivateRoute
                exact
                path="/stripe/redirect"
                component={StripeRedirect}
              />
              <PrivateRoute exact path="/sales" component={Sales} />
              <PrivateRoute exact path="/team" component={Team} />
              <PrivateRoute
                exact
                path="/team/active"
                component={ActiveTeamMembers}
              />
              <PrivateRoute
                exact
                path="/team/:id"
                component={TeamMemberDetails}
              />
              <PrivateRoute exact path="/account" component={Account} />
              <PrivateRoute exact path="/not-found" component={NotFound} />
              <PrivateRoute
                exact
                path="/server-error"
                component={ServerError}
              />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default observer(DashboardRoutes);
