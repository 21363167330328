import { LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import { Badge, Button, Dropdown, Input, Menu, Space, Spin, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import DeleteConfirmationModal from '../../../app/common/modals/DeleteConfirmationModal';
import EmptyList from '../../../app/layout/EmptyList';
import { AppUser } from '../../../app/models/appUser';
import { useStore } from '../../../app/stores/store';
import AddTeamMember from './AddTeamMember';
import agent from '../../../app/api/Agent';
import { useParams } from 'react-router-dom';
import { navigate } from '../../../app/common/util/common';

function TeamMemberList() {
  const { subscriptionsStore, teamStore, userStore, modalStore } = useStore();
  const {
    subscriptionUsers,

    removeUser,
  } = subscriptionsStore;
  const {
    deleting,

    deleteUser,
    setSelectedMember,
  } = teamStore;
  const { user } = userStore;
  const { closeModal, openModal } = modalStore;
  const [deletingId, setDeletingId] = useState(-1);
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const { subId } = useParams<{ subId?: string }>();

  async function confirmDelete(record: AppUser) {
    if (!user) return;

    if (record.id === user.id) {
      toast.error('You cannot delete your own account.');
      return;
    }

    if (
      !user.isSysAdmin &&
      !user.isAdministrator &&
      (record.isManager || record.isAdministrator)
    ) {
      toast.error('You do not have the permissions to delete this user.');
      return;
    }

    openModal(
      true,
      <DeleteConfirmationModal
        title="Delete User"
        cancel={closeModal}
        confirm={() => callDelete(record)}
        message="Are you sure you want to delete this user?"
      />,
      'Changing Package',
      true,
      'delete-confirmation'
    );
  }

  async function callDelete(record: AppUser) {
    try {
      closeModal();
      setDeletingId(record.id);
      await deleteUser({
        userToDelete: record.id,
        subscriptionId: user!.subscriptionId,
      });

      removeUser(record.id);
    } catch (error) {
      console.error(error);
    } finally {
      setDeletingId(-1);
    }
  }

  async function resendInvite(record: AppUser) {
    try {
      if (!record?.email) return;
      await agent.Account.resendEmailConfirm(record?.email);
    } catch (error) {
      console.error(error);
    }
  }

  async function openMember(e: AppUser) {
    if (!user) return;

    if (!user.isSysAdmin && !user.isManager && !user.isAdministrator) {
      toast.error('You do not have the permissions to edit this user');
      return;
    }

    await setSelectedMember(null);
    navigate(`/team/${e.id}`);
  }

  const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'fullName',
      render: (name: string, record: AppUser) => (
        <div className="team-member-name">{name}</div>
      ),
    },
    {
      title: 'Roles',
      dataIndex: 'isAdministrator',
      key: 'userRoles',
      render: (isAdministrator: boolean, record: AppUser) => (
        <div>
          {[
            isAdministrator ? 'Admin' : '',
            record.isManager ? 'Manager' : '',
            record.isPhotographer ? 'Photographer' : '',
          ].join(', ')}
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Tel Number',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (id: number, record: AppUser) => (
        <Badge
          style={{
            backgroundColor: record.isVerified
              ? 'var(--primary-green)'
              : 'var(--primary-red)',
          }}
          count={record.isVerified ? 'verified' : 'not verified'}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      width: '50px',
      key: 'id',
      render: (id: number, record: AppUser) => (
        <>
          {deleting && deletingId === id ? (
            <Spin indicator={loadingIcon} />
          ) : (
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                disabled={deleting}
                overlay={
                  <Menu>
                    <Menu.Item
                      key="0"
                      onClick={() => {
                        confirmDelete(record);
                      }}>
                      Delete
                    </Menu.Item>
                    {record.isVerified === false && (
                      <Menu.Item
                        key="1"
                        onClick={() => {
                          resendInvite(record);
                        }}>
                        Resend invite
                      </Menu.Item>
                    )}
                  </Menu>
                }
                trigger={['click']}
                placement="bottomRight"
                overlayStyle={{ width: '150px' }}>
                <MoreOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
              </Dropdown>
            </div>
          )}
        </>
      ),
    },
  ];

  function enterPress(e: any) {
    setSearchValue(e.target.value);
  }

  function clear(e: any) {
    if (e.target.value.trim() === '') setSearchValue('');
  }

  function searchList() {
    setSearchValue(inputValue);
  }

  function openAddTeamMember() {
    openModal(true, <AddTeamMember />, 'Add New Team Member', true);
  }

  return (
    <div className="team-list">
      <Space size={10}>
        <Input
          onPressEnter={(e) => enterPress(e)}
          onBlur={(e) => setInputValue(e.target.value)}
          allowClear
          onChange={clear}
        />
        <Button type="primary" onClick={searchList}>
          Search
        </Button>
      </Space>

      {[...subscriptionUsers].filter(
        (s) =>
          (s.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            s.email.toLowerCase().includes(searchValue.toLowerCase())) &&
          (subId ? s.subscriptionId === Number(subId) : true)
      ).length < 1 ? (
        <EmptyList
          message="There are no team members to show. Click below to add one."
          buttonText="Add Team Member"
          buttonAction={openAddTeamMember}
          customImage="/assets/images/people-list.svg"
        />
      ) : (
        <>
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  openMember(record);
                },
              };
            }}
            rowClassName="pointer"
            style={{ marginTop: '20px' }}
            dataSource={[...subscriptionUsers].filter(
              (s) =>
                (s.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                  s.email.toLowerCase().includes(searchValue.toLowerCase())) &&
                (subId ? s.subscriptionId === Number(subId) : true)
            )}
            rowKey={'id'}
            columns={columns}
          />
        </>
      )}
    </div>
  );
}

export default observer(TeamMemberList);
