import React, { useEffect, useState } from 'react';

interface Props {
  url: string;
}

function TableImagePreview(props: Props) {
  const [preview, setPreview] = useState<string>('');
  useEffect(() => {
    let tempUrl = props.url;
    if (props.url.startsWith('http://')) {
      tempUrl = props.url.replace('http://', 'https://');
    }

    setPreview(tempUrl);
  }, [props.url, setPreview]);

  const [previewLoaded, setPreviewLoaded] = useState<boolean>(false);

  return (
    <>
      <img
        className="project-template-preview"
        style={{ display: previewLoaded ? 'block' : 'none' }}
        onLoad={() => setPreviewLoaded(true)}
        src={preview}
        alt={'thumbnail'}
      />
      <img
        className="project-template-preview template-placeholder"
        style={{ display: previewLoaded ? 'none' : 'block' }}
        src={'/assets/images/landscape-photo.jpg'}
        alt={'thumbnail placeholder'}
      />
    </>
  );
}

export default TableImagePreview;
