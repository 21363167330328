import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, notification, Row } from 'antd';
import { useStore } from '../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { EditSubscriptionUser } from '../../../app/models/subscriptions/editSubscriptionUser';
import { toast } from 'react-toastify';

interface Props {
  changeTab: (key: string) => void;
}

function Profile(props: Props) {
  const { changeTab } = props;

  const { accountStore, userStore } = useStore();
  const {
    selectedUserDetails,
    submitting,

    updateDetails,
  } = accountStore;
  const { user } = userStore;

  const [packageName, setPackageName] = useState('Starter');
  useEffect(() => {
    if (!selectedUserDetails) return;

    switch (selectedUserDetails.subscriptionPackage) {
      default:
      case 1:
        setPackageName('Starter');
        break;

      case 2:
        setPackageName('Premium');
        break;

      case 3:
        setPackageName('Pay As You Go');
        break;
    }
  }, [setPackageName]);

  const openErrorNotification = (type: any) => {
    notification.error({
      duration: 45,
      className: 'submit-notification-error',
      message: 'Error',
      description: 'There was an error updating the account information',
    });
  };

  async function getValues(values: any) {
    try {
      if (!selectedUserDetails || !user) return;

      const dto: EditSubscriptionUser = {
        id: selectedUserDetails.id,
        name: values.name,
        email: values.email,
        userName: selectedUserDetails.email,
        displayName: values.name,
        companyName: values.companyName,
      };

      await updateDetails(selectedUserDetails.id, dto);
      toast.success('Saved account details!');
    } catch (error) {}
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    openErrorNotification('error');
  };

  return (
    <>
      <h3 className="tab-header" style={{ marginBottom: '20px' }}>
        Details
      </h3>
      <Row className="personal-details">
        <Col span={12}>
          <h2>Personal Details</h2>
          <Form
            requiredMark={false}
            name="basic"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 14 }}
            initialValues={{
              name: selectedUserDetails?.name,
              email: selectedUserDetails?.email,
              companyName: selectedUserDetails?.companyName,
            }}
            onFinish={getValues}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Form.Item
              label="Full name:"
              name="name"
              rules={[{ required: true, message: 'Please input your name!' }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Email:"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'This is not a valid email',
                },
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}>
              <Input disabled={false} />
            </Form.Item>

            {!(user ?? { isSysAdmin: false }).isSysAdmin && (
              <Form.Item
                label="Company Name:"
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: 'Please input your company name!',
                  },
                ]}>
                <Input disabled={!user || !user.isAdministrator} />
              </Form.Item>
            )}

            <Form.Item wrapperCol={{ span: 15 }}>
              <Button loading={submitting} type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Col>

        <Col span={12}>
          {!(user ?? { isSysAdmin: false }).isSysAdmin && (
            <>
              <h2>Current Subscription</h2>
              <p style={{ fontWeight: 'bold' }}>
                You are currently on the {packageName} package.
              </p>
              <p>
                Click{' '}
                <span
                  className={'account-change-tab'}
                  onClick={() => changeTab('2')}>
                  here
                </span>{' '}
                to change your subscription.
              </p>
            </>
          )}
        </Col>
      </Row>
    </>
  );
}

export default observer(Profile);
