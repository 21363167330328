import Cookies from 'js-cookie';
import { makeAutoObservable } from 'mobx';

export default class ModalStore {
  openState = false;
  bodyContent: JSX.Element | null = null;
  modalTitle: string = '';
  removeFooter: boolean = false;
  className: string = '';
  disableClose: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  openModal = (
    state: boolean,
    content: JSX.Element | null,
    title: string,
    removeFooter = false,
    className = '',
    disableClose = false
  ) => {
    this.openState = state;
    this.bodyContent = content;
    this.modalTitle = title;
    this.removeFooter = removeFooter;
    this.className = className;
    this.disableClose = disableClose;
  };

  setState = (state: boolean) => {
    this.openState = state;
  };

  SetContent = (content: JSX.Element) => {
    this.bodyContent = content;
  };

  closeModal = () => {
    this.openState = false;
  };

  downloadModal = (body: JSX.Element) => {
    const showModal = Cookies.get('happysnappy_safariMessage');

    if ((showModal ?? '') === 'hide') return;

    this.openModal(true, body, 'Safari Downloads', true, '');
  };
}
