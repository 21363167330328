export const COOKIES = {
  SAFARI_MESSAGE: 'happysnappy_safariMessage',
};

export const CURRENCY_SYMBOLS: { [key: string]: string } = {
  ZAR: 'R',
  USD: '$',
  EUR: '€',
  GBP: '£',
  INR: '₹',
  AUD: '$',
};
