import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useParams } from 'react-router-dom';
import { useStore } from '../../app/stores/store';
import { observer } from 'mobx-react-lite';
import NotFound from '../errors/NotFound';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { RoleOptions } from '../../app/common/options/selectOptions';
import { history } from '../..';
import { EditTeamMemberDto } from '../../app/models/team/editTeamMembersDto';
import { toast } from 'react-toastify';
import { getActiveSubscriptionId } from '../../app/common/util/common';

function TeamMemberDetails() {
  const { userStore, teamStore } = useStore();
  const {
    selectedMember,
    loading,
    submitting,
    memberRoles,

    getMember,
    editMember,
  } = teamStore;
  const { user } = userStore;

  const { id } = useParams<{ id: string }>();
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const activeSubscriptionId = getActiveSubscriptionId();

  useEffect(() => {
    if (!id || !user || !activeSubscriptionId) return;

    getMember(activeSubscriptionId, +id);
  }, [getMember, id, activeSubscriptionId]);

  useEffect(() => {
    if (!user) return;
    if (!selectedMember || !id) return;

    if (!user.isSysAdmin && !user.isAdministrator && !user.isManager) {
      toast.error('You do not have the permissions to view or edit this user.');
      history.push('/team');
      return;
    }

    setCanEdit(
      user.isAdministrator ||
        (user.isManager &&
          selectedMember.isPhotographer &&
          !selectedMember.isAdministrator &&
          !selectedMember.isManager)
    );
  }, [selectedMember]);

  async function onFinish(e: any) {
    if (!selectedMember) return;

    const dto: EditTeamMemberDto = {
      id: selectedMember.id,
      name: e.name,
      email: e.email,
      phone: e.phone,
      isAdministrator: e.role.includes('admin'),
      isManager: e.role.includes('manager'),
      isPhotographer: e.role.includes('photographer'),
    };

    await editMember(selectedMember.subscriptionId, selectedMember.id, dto);
    history.goBack();
  }
  const [form] = Form.useForm();
  if (loading) return <LoadingComponent content="Loading member details..." />;
  if (!selectedMember) return <NotFound />;

  const { Option } = Select;
  return (
    <>
      <div className="team-member-details">
        <div className={'team-member-details-header'}>
          <h2 style={{ margin: '0' }}>Team Member Details</h2>

          <div className="team-member-details-buttons">
            <Button
              loading={submitting}
              className="team-member-details-buttons-back yellow-ant-btn"
              onClick={history.goBack}>
              Back
            </Button>
            {!user!.isSysAdmin && (
              <Button
                type={'primary'}
                className="team-member-details-buttons-save"
                onClick={form.submit}
                loading={submitting}>
                Save
              </Button>
            )}
          </div>
        </div>
        <Row className="team-member-details-form">
          <Col span={12}>
            <Form
              requiredMark={false}
              name="basic"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              initialValues={{
                name: selectedMember.name,
                email: selectedMember.email,
                phone: selectedMember.phone,
                role: memberRoles,
              }}
              onFinish={onFinish}
              form={form}
              autoComplete="off">
              <Form.Item
                label="Name:"
                name="name"
                rules={[
                  { required: true, message: 'This field is required!' },
                ]}>
                <Input disabled={!canEdit} />
              </Form.Item>

              <Form.Item
                name={'email'}
                label="Email:"
                rules={[
                  {
                    type: 'email',
                    message: 'This is not a valid email',
                  },
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}>
                <Input />
              </Form.Item>

              <Form.Item
                label="Tel. Number:"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'This field is required!',
                  },
                  {
                    pattern: new RegExp(
                      '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$'
                    ),
                    message: 'Invalid phone number.',
                  },
                ]}>
                <Input disabled={!canEdit} />
              </Form.Item>

              <Form.Item
                label="Role:"
                name="role"
                rules={[
                  {
                    required: true,
                    message: 'This field is required!',
                  },
                ]}>
                <Select
                  mode="multiple"
                  disabled={
                    user!.isManager &&
                    !(user!.isSysAdmin || user!.isAdministrator)
                  }>
                  {RoleOptions.map((role) => (
                    <Option value={role.value} key={role.key}>
                      {role.display}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default observer(TeamMemberDetails);
