import { Popover, Tag } from 'antd';
import React, { useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { FIELDS } from '../options/enums';
import { observer } from 'mobx-react-lite';

interface Props {
  color: string;
  callBack: (color: string, field: string) => void;
  field: string;
  readonly?: boolean;
}

function ColourBanner(props: Props) {
  const { readonly = false } = props;
  const [color, setColor] = useState(props.color);

  function sendColour(e: boolean) {
    if (!e) {
      props.callBack(color, props.field);
    }
  }

  function changeColor(e: string) {
    setColor(e);
    sendColour(false);
  }

  if (readonly)
    return (
      <Tag color={color} style={{ width: '140px', minWidth: '0' }}>
        Click to change
      </Tag>
    );

  return (
    <>
      <Popover
        onVisibleChange={sendColour}
        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
        trigger="click"
        content={
          <div className={'theme-color'}>
            <HexColorPicker
              color={color}
              onChange={changeColor}
              className="theme-color-picker"
            />
            <HexColorInput
              color={color}
              onChange={changeColor}
              className={'ant-input theme-color-input'}
              prefixed
            />
          </div>
        }>
        <Tag color={color} style={{ width: '140px', minWidth: '0' }}>
          Click to change
        </Tag>
      </Popover>
    </>
  );
}

export default observer(ColourBanner);
