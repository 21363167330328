import { Layout, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import TemplatePreviewToolbar from './TemplatePreviewToolbar';
import { useStore } from '../../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { fabric } from 'fabric';
import { getScale } from '../../../../app/common/util/canvasScale';
import { InfoCircleOutlined } from '@ant-design/icons';
import { capitalizeFirstLetter } from '../../../../app/common/util/capitalize';

function TemplateReview() {
  const { fabricStore, templatesStore } = useStore();
  const { createCanvas, setCanvasHandler, updateScale, canvas } = fabricStore;
  const {
    imageSetOrientation,
    templateImages,
    selectedTemplate,
    setShowingBackground,
  } = templatesStore;

  useEffect(() => {
    if (!selectedTemplate) return;

    setShowingBackground(selectedTemplate.isGreenScreen ?? false);

    const greenScreenOptions =
      selectedTemplate && selectedTemplate.isGreenScreen
        ? {}
        : { backgroundColor: 'white' };

    let scale = getScale(window.innerWidth);
    createCanvas(
      'template-canvas',
      {
        ...greenScreenOptions,
        height: 1748,
        width: 2480,
        preserveObjectStacking: true,
        selection: false,
      },
      selectedTemplate.isGreenScreen!,
      scale,
      templateImages?.backgroundUrl
    );
  }, [createCanvas, setCanvasHandler]);

  useEffect(() => {
    window.addEventListener('resize', windowResize);

    return () => {
      window.removeEventListener('resize', windowResize);
    };
  });

  function windowResize(e: any) {
    if (!canvas) return;
    let scale = getScale(window.innerWidth);
    updateScale(scale, canvas);
  }

  return (
    <>
      <Layout className="template-preview">
        <div className="template-preview-dimensions">
          <p>
            Base Width:{' '}
            {imageSetOrientation === 'landscape' ? '2480px' : '1748px'}
          </p>
          <strong style={{ margin: '0 20px' }}>-</strong>
          <p>
            Base Height:{' '}
            {imageSetOrientation === 'landscape' ? '1748px' : '2480px'}
          </p>
          <Tooltip
            className="ratio-tooltip"
            title={`Set phone camera to ${
              imageSetOrientation === 'landscape' ? '4:3' : '3:4'
            }`}>
            <InfoCircleOutlined />
          </Tooltip>
          <p></p>
        </div>

        <div style={{ margin: 'auto', border: '1px solid black' }}>
          <canvas id="template-canvas" />
        </div>

        <TemplatePreviewToolbar />
      </Layout>
    </>
  );
}

export default observer(TemplateReview);
