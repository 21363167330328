import { makeAutoObservable, runInAction } from 'mobx';
import { Template } from '../models/templates/template';
import Agent from '../api/Agent';
import { ProjectPostDTO } from '../models/projects/projectPostDTO';
import { ProjectPutDTO } from '../models/projects/projectPutDTO';
import { ProjectAddTemplate } from '../models/projects/projectAddTemplate';
import agent from '../api/Agent';
import { AppUser } from '../models/appUser';
import { ProjectUser } from '../models/projects/projectUser';
import { ProjectDTO } from '../models/projects/projectDto';
import { ProjectTemplates } from '../models/projects/projectTemplates';
import { ProjectImagesDto } from '../models/projects/projectImagesDto';
import { toast } from 'react-toastify';
import { PaginationParams } from '../models/pagination';
import { yyyymmdd } from '../common/util/date';
import { ProjectImage } from 'src/app/models/projects/projectImage';

const Projects = Agent.Projects;

export class ProjectStore {
  loading: boolean = false;
  loadingTemplates: boolean = false;
  loadingUsers: boolean = false;
  loadingPhotos: boolean = false;
  submitting: boolean = false;
  submittingUsers: boolean = false;
  deleting: boolean = false;
  deletingUsers: boolean = false;

  addingTemplate: boolean = false;
  totalPhotoCount: number = 0;
  filteredPhotoCount: number = 0;
  selectedPopup = {
    title: 'Enter and win!',
    message:
      'Submit your details below and stand a chance to an amazing prize.',
    terms: '',
    data_collection: ['email', 'telephone', 'name'],
    collection_point: 'load',
    frequency: 0,
    force_submit: false,
    terms_mode: 'checkbox',
    theme: {
      header: '#e5e8ea',
      footer: '#e5e8ea',
      swapButtons: false,
    },
  };
  viewPopup: boolean = false;

  projectPhotosPagination: PaginationParams = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
  };
  projectPhotos: ProjectImagesDto | null = null;
  selectedProjectPhotos: ProjectImage[] = [];

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setLoadingTemplates = (value: boolean) => {
    this.loadingTemplates = value;
  };

  setLoadingPhotos = (value: boolean) => {
    this.loadingPhotos = value;
  };

  setSubmitting = (value: boolean) => {
    this.submitting = value;
  };

  setDeletingUsers = (value: boolean) => {
    this.deletingUsers = value;
  };

  setSubmittingUsers = (value: boolean) => {
    this.submittingUsers = value;
  };

  setLoadingUsers = (value: boolean) => {
    this.loadingUsers = value;
  };

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * General project methods/variables:
   * @Param: selectedProject - the project being viewed
   */

  selectedProject: ProjectDTO | null = null;
  projectsRegistry = new Map<number, ProjectDTO>();

  setSelectedProject = (project: ProjectDTO | null) => {
    this.selectedProject = project;
  };

  createProject = async (dto: ProjectPostDTO) => {
    try {
      this.setSubmitting(true);
      const result = await Projects.create(dto);
      this.setSelectedProject(result);
      return result.id;
    } catch (error) {
      throw error;
    } finally {
      this.setSubmitting(false);
    }
  };

  listProjects = async (subscriptionId: number) => {
    try {
      this.setLoading(true);
      this.projectsRegistry.clear();
      const result = await Projects.list(subscriptionId);
      result.forEach((p) => this.setProject(p));
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false);
    }
  };

  private setProject = (project: ProjectDTO) => {
    this.projectsRegistry.set(project.id, project);
  };

  get projectsArray() {
    return Array.from(this.projectsRegistry.values());
  }

  clearLast = () => {
    this.totalPhotoCount = 0;
    this.projectPhotos = null;
    this.selectedProjectPhotos = [];
  };

  fetchProject = async (id: string) => {
    try {
      this.setLoading(true);
      this.totalPhotoCount = 0;
      this.setPhotoPaginationParams({
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,
      });

      let project = this.projectsRegistry.get(+id);
      if (project) {
        this.setSelectedProject(project);
      } else {
        project = await Projects.find(id);
        this.setSelectedProject(project);
      }
      if (!this.projectPhotos) {
        const endDate = yyyymmdd(new Date());
        const result = await this.getPhotos(project.id, '20000101', endDate, 1);
        if (result) {
          runInAction(() => {
            this.totalPhotoCount = result.data.totalImages;
          });
        }
      }
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false);
    }
  };

  updateProject = async (id: number, dto: ProjectPutDTO) => {
    try {
      this.setSubmitting(true);
      await Projects.update(id.toString(), dto);
      toast.success('Project settings saved!');
    } catch (error) {
      throw error;
    } finally {
      this.setSubmitting(false);
    }
  };

  addTemplates = async (id: string, templates: ProjectAddTemplate) => {
    try {
      this.setSubmitting(true);
      await Projects.add_templates(id, templates);
    } catch (error) {
      throw error;
    } finally {
      this.setSubmitting(false);
    }
  };

  removeProjectTemplate = async (id: number) => {
    try {
      this.setSubmitting(true);
      if (!this.selectedProject) return;
      const dto: ProjectAddTemplate = {
        templateIds: [id],
      };

      const result = await Projects.delete_templates(
        this.selectedProject.id.toString(),
        dto
      );

      if (result.message.toLowerCase() === 'removed') {
        const index = this.selectedProject.templateGroupTemplates.findIndex(
          (t: ProjectTemplates) => t.templateId === id
        );
        if (index !== -1)
          this.selectedProject.templateGroupTemplates.splice(index, 1);
      } else {
        return result;
      }
    } catch (error) {
      throw error;
    } finally {
      this.setSubmitting(false);
    }
  };

  get projectTemplates(): Template[] {
    if (!this.selectedProject) return [];

    return this.selectedProject.templateGroupTemplates.map(
      (value: ProjectTemplates) => value.template
    );
  }

  get projectUsers(): AppUser[] {
    if (!this.selectedProject) return [];

    return this.selectedProject.templateGroupAppUsers
      .filter((a) => a.appUser)
      .map((value: ProjectUser) => value.appUser);
  }

  fetchProjectTemplates = async () => {
    try {
      if (!this.selectedProject) return;
      this.setLoadingTemplates(true);

      const result = await Projects.get_templates(
        this.selectedProject.id.toString()
      );
      const project = this.selectedProject;
      runInAction(() => {
        project.templateGroupTemplates = result.map((t) => {
          return {
            templateGroupId: project.id,
            templateGroup: {},
            templateId: t.id,
            template: t,
          } as ProjectTemplates;
        });
      });
    } catch (error) {
      throw error;
    } finally {
      this.setLoadingTemplates(false);
    }
  };

  setAddingTemplate = async (value: boolean) => {
    this.addingTemplate = value;
  };

  setSelectedPopup = (values: any) => {
    this.selectedPopup = values;
  };

  setViewPopup = (value: boolean) => {
    this.viewPopup = value;
  };

  addUsers = async (id: number, users: number[]) => {
    try {
      this.setSubmittingUsers(true);
      await agent.Projects.addUsers(id, {
        userIds: users,
      });
    } catch (error) {
      throw error;
    } finally {
      this.setSubmittingUsers(false);
    }
  };

  setDeleting = (value: boolean) => {
    this.deleting = value;
  };

  archiveProject = async (id: number) => {
    try {
      this.setDeleting(true);
      const result = await agent.Projects.archive(id);
      if (result) {
        if (this.selectedProject && this.selectedProject.id === id) {
          this.setSelectedProject(null);
        }

        this.projectsRegistry.delete(id);
      }
    } catch (error) {
      throw error;
    } finally {
      this.setDeleting(false);
    }
  };

  removeUsers = async (id: number, users: number[]) => {
    try {
      this.setDeletingUsers(true);

      const result = await agent.Projects.removeUsers(id, { userIds: users });
      if (result) {
        runInAction(() => {
          users.forEach((id) => {
            if (!this.selectedProject) return;

            const index = this.selectedProject.templateGroupAppUsers.findIndex(
              (u) => u.userId === id
            );
            this.selectedProject.templateGroupAppUsers.splice(index, 1);
          });
        });
      }
    } catch (error) {
      throw error;
    } finally {
      this.setDeletingUsers(false);
    }
  };

  getUsers = async () => {
    try {
      if (!this.selectedProject) return;
      this.setLoadingUsers(true);

      const result = await agent.Projects.getUsers(this.selectedProject.id);
      runInAction(() => {
        this.selectedProject!.templateGroupAppUsers = result;
      });
    } catch (error) {
      throw error;
    } finally {
      this.setLoadingUsers(false);
    }
  };

  setProjectPhotos = (value: ProjectImagesDto | null) => {
    this.projectPhotos = value;
    this.selectedProjectPhotos = [];

    if (value === null) return;

    this.filteredPhotoCount = value.totalFilterCount;

    if (value.totalImages !== this.totalPhotoCount) {
      this.totalPhotoCount = value.totalImages;
    }
  };
  setSelectedProjectPhotos = (value: ProjectImage[]) => {
    this.selectedProjectPhotos = value;
  };

  setFilteredPhotoCount = (value: number) => {
    this.filteredPhotoCount = value;
  };

  getPhotos = async (
    projectId: number,
    startDate: string,
    endDate: string,
    newPage?: number,
    templateId?: string,
    qrCode?: string,
    unlinked: null | boolean = null,
    itemsPerPage: number = 10
  ) => {
    try {
      this.setLoadingPhotos(true);

      const result = await agent.Projects.projectPhotos({
        templateGroupId: projectId.toString(),
        templateId: templateId,
        startDate: startDate,
        endDate: endDate,
        currentPage: newPage!.toString(),
        itemsPerPage: itemsPerPage.toString(),
        totalItems: this.projectPhotosPagination.totalItems.toString(),
        qrCode: qrCode,
        unlinked: unlinked === null ? undefined : unlinked.toString(),
      });
      this.setProjectPhotos(result.data);
      this.setPhotoPaginationParams(result.params);
      return result;
    } catch (error) {
      throw error;
    } finally {
      this.setLoadingPhotos(false);
    }
  };
  linkPhotosToNewQRCode = async (qrCode: string) => {
    try {
      this.setLoadingPhotos(true);
      const selectedProjectPhotosIds = this.selectedProjectPhotos.map(
        (x) => x.snaptImageId
      );
      return await agent.Photos.linkPhotosToNewQRCode(
        selectedProjectPhotosIds,
        qrCode
      );
    } catch (error) {
      throw error;
    } finally {
      this.setLoadingPhotos(false);
    }
  };
  unwatermarkPhotos = async (qrCode: string) => {
    try {
      this.setLoadingPhotos(true);
      const selectedProjectPhotosIds = Array.from(
        new Set(this.selectedProjectPhotos.map((x) => x.snaptImageId))
      );
      return await agent.Photos.unwatermarkImages(
        selectedProjectPhotosIds,
        qrCode
      );
    } catch (error) {
      throw error;
    } finally {
      this.setLoadingPhotos(false);
    }
  };

  setPhotoPaginationParams = (value: PaginationParams) => {
    this.projectPhotosPagination = value;
  };

  changeMainValue = (templateId: number, field: string, value: boolean) => {
    if (!this.selectedProject) return;

    const template = this.selectedProject.templateGroupTemplates.find(
      (tg: ProjectTemplates) => tg.templateId === templateId
    );
    if (!template) return;

    switch (field) {
      case 'website':
        template.isTemplateGroupMainWebsiteId = value;
        break;

      case 'popup':
        template.isTemplateGroupMainPopUpId = value;
        break;
    }
  };

  getProject = (id: number) => {
    return this.projectsRegistry.get(id);
  };

  readonlySettings: boolean = false;
  setReadonlySettings = (value: boolean) => {
    this.readonlySettings = value;
  };
}
