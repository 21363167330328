import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Space, Spin } from 'antd';

interface Props {
  content?: string;
}

export default function LoadingComponent({ content }: Props) {
  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

  return (
    <>
      <div className="loading-component">
        <Space
          direction={'vertical'}
          align={'center'}
          style={{ margin: 'auto' }}>
          <Spin indicator={antIcon} />
          <h3>{content}</h3>
        </Space>
      </div>
    </>
  );
}
