import React, { useEffect, useState } from 'react';
import { Layout, Tabs } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import Profile from './components/Profile';
import Subscription from './components/Subcriptions';
import { useStore } from '../../app/stores/store';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { observer } from 'mobx-react-lite';
import useQuery from '../../app/common/util/hooks';
import SubscriptionsList from './components/SubscriptionsList';
import ImageStorage from 'src/features/account/components/ImageStorage';

function Account() {
  const { Content } = Layout;
  const { TabPane } = Tabs;

  const { accountStore, userStore, subscriptionsStore } = useStore();
  const { fetchDetails, loading } = accountStore;
  const { user } = userStore;
  const {
    loadingSubscriptions,

    fetchAllSubscriptions,
  } = subscriptionsStore;

  const [activeTab, setActiveTab] = useState('1');
  const query = useQuery();

  useEffect(() => {
    fetchDetails();

    if ((user ?? { isSysAdmin: false }).isSysAdmin) {
      fetchAllSubscriptions();
    }

    const tab = query.get('tab');
    if (tab) {
      if (tab === '1' || tab === '2' || tab === '3') {
        if (tab === '3' && user?.isSysAdmin !== true) return;
        setActiveTab(tab);
      }
    }
  }, [fetchDetails]);

  if (loading || loadingSubscriptions)
    return <LoadingComponent content="Loading user details..." />;

  return (
    <>
      <Layout className="account">
        <Header className="account-header">
          <div>
            <h2>Account settings</h2>
          </div>
        </Header>

        <Content>
          <Tabs
            defaultActiveKey="1"
            className="profile-details-tabs"
            onChange={(e) => setActiveTab(e)}
            activeKey={activeTab}>
            <TabPane key="1" tab="Details">
              <Profile changeTab={setActiveTab} />
            </TabPane>

            {!(!user || !user.isAdministrator) && (
              <TabPane key="2" tab="Subscription">
                <Subscription />
              </TabPane>
            )}
            {(user?.isAdministrator || user?.isSysAdmin) && (
              <TabPane key="3" tab="Storage">
                <ImageStorage />
              </TabPane>
            )}
            {(user ?? { isSysAdmin: false }).isSysAdmin && (
              <TabPane key="4" tab="All Subscriptions">
                <SubscriptionsList />
              </TabPane>
            )}
          </Tabs>
        </Content>
      </Layout>
    </>
  );
}

export default observer(Account);
