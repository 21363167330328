export enum EntityStatus {
  Active,
  Suspended,
  Deleted,
  Archived,
  DeActivated,
}

export enum FIELDS {
  HEADER_FOOTER_BG,
  HEADER_FOOTER_TEXT,
  MESSAGE_BODY_BG,
  MESSAGE_BODY_TEXT,
  SUBMIT_BUTTON_BG,
  SUBMIT_BUTTON_TEXT,
  DECLINE_BUTTON_BG,
  DECLINE_BUTTON_TEXT,
}

export enum SaleType {
  IndividualImages,
  AllImages,
  QRCode,
}

export enum SaleStatus {
  ValidatingCheckout,
  BadCheckoutRequest,
  CheckoutCreated,
  CheckoutCancelled,
  PaymentCancelled,
  PaymentFailed,
  PaymentVerificationFailed,
  PaymentSuccess,
}

export enum SaleAbortReason {
  CheckoutCancelled,
  PaymentCancelled,
  PaymentFailed,
}
