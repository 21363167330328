import { Button, Layout } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useStore } from '../../../app/stores/store';
import TemplateLayer from './TemplateLayer';
import TemplateSelectedLayer from './TemplateSelectedLayer';
import update from 'immutability-helper';
import { v4 as uuid } from 'uuid';
import { fabric } from 'fabric';
import ImageUploader from '../../../app/common/fileUploader/ImageUploader';
import { TemplateImageLayerFull } from '../../../app/models/templates/templateImageLayerFull';

function TemplateLayers() {
  const { templatesStore, fabricStore, modalStore } = useStore();
  const {
    // Variables
    templateImages,
    templateSetImages,
    selectedImage,
    selectedTemplate,
    selectedImageSet,
    showingBackground,
    imageSetOrientation,

    // Methods
    addImageLayer,
    uploadTemplateFile,
    setTemplateSetImages,
    setSelectedImage,
    updateLayerPosition,
    updateTemplateImages,
    setDisableSubmitLayers,
    setShowingBackground,
    setDisableSubmitSettings,
    updateLayerSize,
  } = templatesStore;

  const { openModal } = modalStore;

  const { addShape, setObjectHandler, getObject, canvas, addBackground } =
    fabricStore;

  // drag index = start index
  // hover index = where it is hovering
  const moveLayer = useCallback(
    (dragIndex: number, hoverIndex: number, objectId: string) => {
      const dragLayer = templateSetImages[dragIndex];
      // changeLevel(dragIndex < hoverIndex, objectId);

      setTemplateSetImages(
        update(templateSetImages, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragLayer],
          ],
        })
      );
    },
    [templateSetImages, setTemplateSetImages]
  );

  async function submitImage(file: any, size: any) {
    const url = await uploadTemplateFile(file);
    modalStore.closeModal();

    if (url) {
      const id = uuid();
      addImage(url, id);

      const newImage: TemplateImageLayerFull = {
        id: 0,
        templateImageId: templateImages!.id,
        imageUrl: url,
        thumbUrl: url,
        alignment: 4,
        top: 0,
        left: 0,
        orderIndex: templateSetImages.length,
        newId: id,
        width: size.width,
        height: size.height,
      };
      addImageLayer(newImage);
    }
  }

  function addLayer() {
    openModal(
      true,
      <ImageUploader onSubmit={submitImage} />,
      'Upload an image',
      true
    );
  }

  function addImage(url: string, id: string) {
    fabric.Image.fromURL(url, function (oImg: fabric.Image) {
      oImg.scale(0.8);
      oImg.set('name', id);
      addShape(oImg);
      setObjectHandler('selected', id, setSelected);
      setObjectHandler('moved', id, setMoved);
      setObjectHandler('deselected', id, setDeselected);
      setObjectHandler('scaled', id, setChanged);
    });
  }

  function setDeselected(e: any) {
    setSelectedImage(null);
  }

  function setChanged(e: fabric.IEvent<Event>) {
    if (e.target === undefined) return;
    const object = getObject(e.target.name ?? '');
    if (!object) return;

    updateLayerSize(
      object.getScaledWidth() / 0.8,
      object.getScaledHeight() / 0.8
    );
  }

  function setSelected(e: fabric.IEvent<Event>) {
    if (!e.target) return;
    const layer = templateSetImages.find(
      (l) => (l.newId ?? '').toString() === e.target!.name
    );
    if (layer) {
      setSelectedImage(layer);
    }
  }

  function setMoved(e: fabric.IEvent<Event>) {
    if (!e.target) return;

    let top = +e.target.top!;
    let left = +e.target.left!;
    updateLayerPosition(top, left);
  }

  function changeBackground() {
    openModal(
      true,
      <ImageUploader onSubmit={submitBackground} imageType="background" />,
      'Upload an image',
      true
    );
  }

  async function submitBackground(file: any) {
    if (!selectedTemplate) return;
    const url = await uploadTemplateFile(file);
    modalStore.closeModal();

    if (url) {
      fabric.Image.fromURL(url, (e: fabric.Image) => {
        addBackground(e, imageSetOrientation);
      });

      updateTemplateImages({
        ...templateImages!,
        backgroundUrl: url,
      });

      if (!showingBackground) {
        setShowingBackground(true);
      }

      setDisableSubmitLayers(
        selectedTemplate.isGreenScreen! &&
          (selectedImageSet!.templateImages[0].backgroundUrl === '' ||
            selectedImageSet!.templateImages[1].backgroundUrl === '')
      );
      setDisableSubmitSettings(
        !selectedTemplate.name ||
          selectedTemplate.name.trim() === '' ||
          !selectedTemplate.description ||
          selectedTemplate.description.trim() === '' ||
          (selectedTemplate.isGreenScreen! &&
            (selectedImageSet!.templateImages[0].backgroundUrl === '' ||
              selectedImageSet!.templateImages[1].backgroundUrl === ''))
      );
    }
  }

  return (
    <>
      <Layout className="template-layers">
        {selectedTemplate && selectedTemplate.isGreenScreen && (
          <div>
            <div className="collapse-subheading">Background Image</div>
            <div
              className="template-layers-greenscreen"
              onClick={changeBackground}>
              {templateImages!.backgroundUrl && showingBackground ? (
                <img src={templateImages!.backgroundUrl} alt={'background'} />
              ) : (
                <div>
                  <p className="ant-upload-text" style={{ marginBottom: '0' }}>
                    Upload background
                  </p>
                  <p
                    className="ant-upload-text"
                    style={{ fontSize: '11px', marginBottom: '0' }}>
                    Please upload a PNG or a JPEG image.
                  </p>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="collapse-subheading">Layers</div>
        <div>
          {templateSetImages &&
            templateSetImages.map((l: any, i) => (
              <TemplateLayer
                moveLayer={moveLayer}
                index={i}
                key={i}
                layer={l}
              />
            ))}
        </div>

        <Button onClick={addLayer}>+ Add New Image Layer</Button>

        {selectedImage && <TemplateSelectedLayer />}
      </Layout>
    </>
  );
}

export default observer(TemplateLayers);
