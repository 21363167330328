import React, { useEffect } from 'react';
import { Button, Layout } from 'antd';
import { useStore } from '../../app/stores/store';
import ProjectsList from './components/ProjectsList';
import AddProjectForm from './components/AddProjectForm';
import { toast } from 'react-toastify';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { observer } from 'mobx-react-lite';
import { getActiveSubscriptionId } from '../../app/common/util/common';

function Projects() {
  const { modalStore, projectStore, userStore, subscriptionsStore } =
    useStore();

  const { user } = userStore;
  const { selectedSubscription } = subscriptionsStore;
  const { projectsArray, loading, listProjects } = projectStore;
  const { openModal } = modalStore;

  useEffect(() => {
    const activeSubscriptionId = getActiveSubscriptionId();
    if (!user || !activeSubscriptionId) return;
    listProjects(activeSubscriptionId);
  }, [listProjects, user, selectedSubscription?.id]);

  function addProject() {
    if (!user) return;
    if (user.subscriptionPackageId === 1 && projectsArray.length >= 1) {
      toast.error(
        'You have reached the maximum number of projects for the Starter Package. Upgrade package to be able to create more!'
      );
      return;
    }

    openModal(true, <AddProjectForm />, 'Create New Project', true);
  }

  if (loading) return <LoadingComponent content="Loading projects..." />;

  const { Header, Content } = Layout;
  return (
    <>
      <Layout className="projects">
        <Header className="view-project-header">
          <div>
            <h2>Projects</h2>

            <Button
              type={'primary'}
              className="projects-add-button"
              onClick={addProject}>
              Add project
            </Button>
          </div>
        </Header>

        <Content>
          <ProjectsList />
        </Content>
      </Layout>
    </>
  );
}

export default observer(Projects);
