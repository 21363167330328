import { SaleStatus, SaleType } from './enums';

export interface IStandardOption<T> {
  value: T;
  display: string;
}

export const TimePeriods = [
  { value: 'today', label: 'Today' },
  { value: '7_days', label: 'Last 7 Days' },
  { value: '14_days', label: 'Last 14 Days' },
  { value: '30_days', label: 'Last 30 Days' },
  { value: 'all', label: 'All time' },
];

export const TimePeriodsCustom = [
  ...TimePeriods,
  { value: 'custom', label: 'Custom' },
];

export const ShareThemes = [
  { value: 'light', label: 'Light' },
  { value: 'dark', label: 'Dark' },
  { value: 'transparent-dark', label: 'Black Transparent' },
  { value: 'transparent-light', label: 'Light Transparent' },
];

export const DefaultWebsiteThemes = [
  {
    key: '1',
    label: 'Light Mode: ',
    value: 'light',
    background: '#D2D2D2',
    frame: '#ffffff',
  },
  {
    key: '2',
    label: 'Dark Mode: ',
    value: 'dark',
    background: '#a8a8a8',
    frame: '#292727',
  },
  {
    key: '3',
    label: 'Primaries: ',
    value: 'primaries',
    background: '#fc1212',
    frame: '#6cccf9',
  },
];

export const DefaultPopupThemes = [
  {
    key: '1',
    label: 'Light Mode: ',
    value: 'light',
    background: '#e5e8ea',
    frame: '#e5e8ea',
  },
  {
    key: '2',
    label: 'Dark Mode: ',
    value: 'dark',
    background: '#a8a8a8',
    frame: '#292727',
  },
  {
    key: '3',
    label: 'Primaries: ',
    value: 'primaries',
    background: '#fc1212',
    frame: '#6cccf9',
  },
];

export const RoleOptions = [
  { key: '1', value: 'admin', display: 'Admin' },
  { key: '2', value: 'manager', display: 'Manager' },
  { key: '3', value: 'photographer', display: 'Photographer' },
];

export const Currencies = [
  { value: 'USD', display: 'US Dollar' },
  { value: 'GBP', display: 'Great British Pound' },
  { value: 'ZAR', display: 'South African Rand' },
  { value: 'AUD', display: 'Australian Dollar' },
  { value: 'CAD', display: 'Canadian Dollar' },
  { value: 'EUR', display: 'Euro' },
  { value: 'INR', display: 'Rupee' },
];

export const SaleStatuses: IStandardOption<SaleStatus>[] = [
  // Payment Success (7) ordered first to ensure it is easily found in the UI where this is used.
  // However the values key values here cannot be changed as they are used in the database as part of the SaleStatus system enum
  { value: 7, display: 'Payment Success' },
  { value: 0, display: 'Validating Checkout' },
  { value: 1, display: 'Bad Checkout Request' },
  { value: 2, display: 'Checkout Created' },
  { value: 3, display: 'Checkout Cancelled' },
  { value: 4, display: 'Payment Cancelled' },
  { value: 5, display: 'Payment Failed' },
  { value: 6, display: 'Payment Verification Failed' },
];

export const PaymentProviders: IStandardOption<string>[] = [
  { value: 'paypal', display: 'PayPal' },
  { value: 'paygate', display: 'PayGate' },
  { value: 'stripe', display: 'Stripe' },
];

export const SaleTypes: IStandardOption<SaleType>[] = [
  { value: 0, display: 'Individual Images' },
  { value: 1, display: 'All Images' },
];

export const QRCodesOutputFormat = [
  // { display: 'Individual PNG', value: 'IndividualPNG' },
  {
    display: 'Individual PNG with QR Code',
    value: 'IndividualPNG_WithAlbumCode',
  },
  // { display: 'Combined PDF', value: 'CombinedPDF' },
];

export const FIELD_NAMES = {
  HEADER_FOOTER_BG: 'headerFooterBackgroundColor',
  HEADER_FOOTER_TEXT: 'headerFooterTextColor',
  MESSAGE_BODY_BG: 'bodyBackgroundColor',
  MESSAGE_BODY_TEXT: 'bodyTextColor',
  SUBMIT_BUTTON_BG: 'submitButtonColor',
  SUBMIT_BUTTON_TEXT: 'submitButtonTextColor',
  DECLINE_BUTTON_BG: 'declineButtonColor',
  DECLINE_BUTTON_TEXT: 'declineButtonTextColor',
};

export const UnlinkedOrLinkedPhotosOptions = [
  {
    value: null,
    label: 'All photos',
    key: 'all',
  },
  {
    value: false,
    label: 'Linked photos',
    key: 'linked',
  },
  {
    value: true,
    label: 'Unlinked photos',
    key: 'unlinked',
  },
];

export const StagingAWSS3Regions = [
  {
    display: 'Europe (London)',
    value: 'eu-west-2',
  },
];
export const AWSS3Regions = [
  // {
  //   display: 'US East (Ohio)',
  //   value: 'us-east-2',
  // },
  // {
  //   display: 'US East (N. Virginia)',
  //   value: 'us-east-1',
  // },
  // {
  //   display: 'US West (N. California)',
  //   value: 'us-west-1',
  // },
  // {
  //   display: 'US West (Oregon)',
  //   value: 'us-west-2',
  // },
  // {
  //   display: 'Africa (Cape Town)',
  //   value: 'af-south-1',
  // },
  // {
  //   display: 'Asia Pacific (Hong Kong)***',
  //   value: 'ap-east-1',
  // },
  // {
  //   display: 'Asia Pacific (Hyderabad)',
  //   value: 'ap-south-2',
  // },
  // {
  //   display: 'Asia Pacific (Jakarta)',
  //   value: 'ap-southeast-3',
  // },
  // {
  //   display: 'Asia Pacific (Melbourne)',
  //   value: 'ap-southeast-4',
  // },
  // {
  //   display: 'Asia Pacific (Mumbai)',
  //   value: 'ap-south-1',
  // },
  // {
  //   display: 'Asia Pacific (Osaka)',
  //   value: 'ap-northeast-3',
  // },
  // {
  //   display: 'Asia Pacific (Seoul)',
  //   value: 'ap-northeast-2',
  // },
  // {
  //   display: 'Asia Pacific (Singapore)',
  //   value: 'ap-southeast-1',
  // },
  // {
  //   display: 'Asia Pacific (Sydney)',
  //   value: 'ap-southeast-2',
  // },
  // {
  //   display: 'Asia Pacific (Tokyo)',
  //   value: 'ap-northeast-1',
  // },
  // {
  //   display: 'Canada (Central)',
  //   value: 'ca-central-1',
  // },
  // {
  //   display: 'China (Beijing)',
  //   value: 'cn-north-1',
  // },
  // {
  //   display: 'Middle East (Bahrain)',
  //   value: 'me-south-1',
  // },
  // {
  //   display: 'Middle East (UAE)',
  //   value: 'me-central-1',
  // },
  // {
  //   display: 'China (Ningxia)',
  //   value: 'cn-northwest-1',
  // },
  // {
  //   display: 'Europe (Frankfurt)',
  //   value: 'eu-central-1',
  // },
  // {
  //   display: 'Europe (Ireland)',
  //   value: 'eu-west-1',
  // },
  {
    display: 'Europe (London)',
    value: 'eu-west-2',
  },
  // {
  //   display: 'Europe (Milan)',
  //   value: 'eu-south-1',
  // },
  // {
  //   display: 'Europe (Paris)',
  //   value: 'eu-west-3',
  // },
  // {
  //   display: 'Europe (Stockholm)',
  //   value: 'eu-north-1',
  // },
  // {
  //   display: 'Europe (Spain)',
  //   value: 'eu-south-2',
  // },
  // {
  //   display: 'Europe (Zurich)',
  //   value: 'eu-central-2',
  // },
  // {
  //   display: 'Israel (Tel Aviv)',
  //   value: 'il-central-1',
  // },
  // {
  //   display: 'South America (São Paulo)',
  //   value: 'sa-east-1',
  // },
  // Note: we are not supporting the following regions at this time
  // {
  //   display: 'AWS GovCloud (US-East)',
  //   value: 'us-gov-east-1',
  // },
  // {
  //   display: 'AWS GovCloud (US-West)',
  //   value: 'us-gov-west-1',
  // },
];
