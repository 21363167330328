import * as xlsx from 'xlsx';

export const toXlsx = (data: Object[], fileName: string) => {
  const fileExtension = '.xlsx';

  const ws = xlsx.utils.json_to_sheet(data);
  const workbook: xlsx.WorkBook = {
    Sheets: {
      Data: ws,
    },
    SheetNames: ['Data'],
  };

  xlsx.writeFile(workbook, fileName + fileExtension);
};
