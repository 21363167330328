import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Select } from 'antd';
import { useStore } from '../../stores/store';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { EntityStatus } from 'src/app/common/options/enums';

function NavBar() {
  const { userStore } = useStore();

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a href="/account">Account</a>
      </Menu.Item>
      <Menu.Item key="1" onClick={userStore.logout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="navbar">
        {userStore?.user?.isSysAdmin && <AccountSelector />}
        {/*<img src='/assets/images/notificationbell.svg' className='notification-icon' alt='notification' />*/}
        <Dropdown
          overlay={menu}
          trigger={['click']}
          placement="bottomRight"
          overlayStyle={{ width: '100px' }}>
          <img
            className="user-icon"
            src="/assets/images/usericon.svg"
            alt="user"
          />
        </Dropdown>
      </div>
    </>
  );
}

export default observer(NavBar);

const AccountSelector = observer(() => {
  const { subscriptionsStore, userStore } = useStore();
  const { user } = userStore;
  const history = useHistory();
  const location = useLocation();
  const { subId } = useParams<{ subId: string }>();

  const {
    allSubscriptions,
    fetchAllSubscriptions,
    selectedSubscription,
    loadingSubscriptions,
  } = subscriptionsStore;

  const [value, setValue] = useState<string | undefined>(
    allSubscriptions.find(
      (s) => s.id === (Number(subId) || user?.subscriptionId)
    )?.accountName ?? selectedSubscription?.companyName
  );

  const handleAccountChange = (
    label: string,
    e: {
      value: string;
      id: number;
    }
  ) => {
    const { id, value } = e;
    if (!id || !allSubscriptions.find((item) => item.id === id)) return;
    setValue(value);
    subId
      ? history.push(location.pathname.replace(`/${subId}/`, `/${id}/`))
      : history.push(`/subscription/${id}/dashboard`);
  };

  useEffect(() => {
    (async () => {
      if (!allSubscriptions.length) {
        await fetchAllSubscriptions();
      }
    })();

    if (allSubscriptions.length) {
      setValue(
        allSubscriptions.find(
          (s) => s.id === (Number(subId) || user?.subscriptionId)
        )?.accountName ?? selectedSubscription?.companyName
      );
    }
  }, [allSubscriptions, subId]);

  const options = allSubscriptions
    .filter((s) => s.status === EntityStatus.Active)
    .map((s) => ({ value: s.accountName, id: s.id }))
    .sort((a, b) => (a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1));

  return (
    <div className="account-selector">
      <Select
        loading={loadingSubscriptions}
        showSearch
        className="account-selector__select"
        aria-roledescription="Change your active account"
        options={options}
        onSelect={handleAccountChange}
        value={value}
        onChange={(value) => setValue(value)}
        filterOption={(inputValue, option) =>
          option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        key={allSubscriptions.length}
        menuItemSelectedIcon
      />
    </div>
  );
});
