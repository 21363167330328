import React from 'react';
import { Button, Card } from 'antd';
import { observer } from 'mobx-react-lite';
import { history } from '../../../index';
import { useStore } from '../../../app/stores/store';

interface Props {
  codes: number;
}

function CodesRemaining(props: Props) {
  const { codes } = props;
  const { userStore } = useStore();
  const { user } = userStore;

  function buy() {
    history.push('/codes?tab=2');
  }

  return (
    <>
      <Card className="codes-remaining">
        <h3>Total number of QR codes remaining:</h3>
        <h2>{codes}</h2>

        {(user ?? { subscriptionPackageId: 1 }).subscriptionPackageId !== 1 && (
          <Button type="primary" onClick={buy}>
            Purchase More QR Codes
          </Button>
        )}
      </Card>
    </>
  );
}

export default observer(CodesRemaining);
