import { PlusOutlined } from '@ant-design/icons';
import { Card, Skeleton, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from '../../../app/stores/store';
import AddTemplateForm from './AddTemplateForm';
import TemplateCard from './TemplateCard';
import { toast } from 'react-toastify';

interface Props {
  type: 'all' | 'green' | 'standard';
  search: string;
}

function TemplatesList(props: Props) {
  const { type, search } = props;
  const { templatesStore, userStore } = useStore();

  const { user } = userStore;

  const {
    loading,
    getTemplates,

    filterTemplates,
  } = templatesStore;

  const { modalStore } = useStore();

  function addTemplate() {
    if (!user) return;
    if (user.subscriptionPackageId === 1 && getTemplates.length >= 1) {
      toast.error(
        'You have reached the maximum number of templates for the Starter Package. Upgrade package to be able to create more!'
      );
      return;
    }

    modalStore.openModal(
      true,
      <AddTemplateForm type={type} from="templates" />,
      'Create New Template',
      true
    );
  }

  return (
    <>
      <Space size={19} wrap>
        <Card className="templates-add-card" onClick={addTemplate}>
          <p>
            <PlusOutlined style={{ color: '#1c8bff' }} /> Add New Template
          </p>
        </Card>

        {getTemplates.length < 1 && loading && (
          <>
            <div className="skel-loader">
              <Skeleton.Button active />
              <Skeleton
                active
                paragraph={{ rows: 2 }}
                style={{ marginLeft: '15px' }}
              />
            </div>

            <div className="skel-loader">
              <Skeleton.Button active />
              <Skeleton
                active
                paragraph={{ rows: 2 }}
                style={{ marginLeft: '15px' }}
              />
            </div>
          </>
        )}

        {getTemplates &&
          filterTemplates(type)
            .filter((t) => t.name?.toLowerCase().includes(search.toLowerCase()))
            .map((t) => <TemplateCard template={t} key={t.id} />)}
      </Space>
    </>
  );
}

export default observer(TemplatesList);
