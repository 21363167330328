import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { history } from '../../index';

export default function NotFound() {
  function goBack() {
    history.goBack();
  }

  return (
    <>
      <div className="not-found-component">
        <SearchOutlined style={{ fontSize: 48 }} />
        <h2>Oops - we've looked everywhere and could not find this.</h2>

        <Button type={'primary'} onClick={goBack}>
          Go back
        </Button>
      </div>
    </>
  );
}
