import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { useParams } from 'react-router-dom';

function NoCodesStarter() {
  const { userStore } = useStore();
  const { user } = userStore;
  const subId = useParams<{ subId: string }>();

  return (
    <>
      <h3>No Codes</h3>

      <p>
        You have reached this months limit for QR Codes.
        <br />
        {user!.isAdministrator || (user!.isSysAdmin && Number(subId))
          ? 'To purchase more QR Codes, upgrade you package under the Account section.'
          : 'In order to get more QR Codes contact subscription administrator to upgrade your package.'}
      </p>
    </>
  );
}

export default observer(NoCodesStarter);
