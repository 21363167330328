import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../../app/stores/store';
import { Space } from 'antd';

export default observer(function ServerError() {
  const { commonStore } = useStore();
  const { error } = commonStore;

  return (
    <Space direction={'vertical'}>
      <h2>Server Error</h2>
      <h4 style={{ color: 'red' }}>{error?.message}</h4>

      {error?.details && (
        <Space direction={'vertical'}>
          <h4>Stack Trace</h4>
          <code style={{ marginTop: '10px' }}>{error?.details}</code>
        </Space>
      )}
    </Space>
  );
});
