import React from 'react';
import { observer } from 'mobx-react-lite';

export interface ValidationIssues {
  title: string;
  issues: string[];
}

interface Props {
  issues: ValidationIssues[];
  message: string;
}

function ProjectValidationModal(props: Props) {
  const { issues, message } = props;

  return (
    <>
      <div className="project-validation-modal-content">
        <div className="modal-hand">
          <img src="/assets/images/modal-hand.svg" alt="modal-hand" />
        </div>

        <div className="pvm-content">
          <div className="pvm-content-title">
            <h3>Woah, not so fast!</h3>
            <p>{message}</p>
          </div>

          {issues.map((i, index) => (
            <div className="pvm-content-issue" key={index}>
              <img src="/assets/images/cog.svg" alt="cog" />
              <div className="pvm-content-issue-details">
                <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                  {i.title}
                </div>

                {i.issues.map((is, index2) => (
                  <div style={{ display: 'flex' }} key={`issue-${index2}`}>
                    <div style={{ color: 'red', fontWeight: 'bold' }}>x</div>
                    <div style={{ marginLeft: '15px' }}>{is}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default observer(ProjectValidationModal);
