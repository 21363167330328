const errors = [
  'No Templates found.',
  'No Template Groups found.',
  'No Templates Found Under the provided subscriptionId',
  'No Data Collections found.',
  'No Sales by Filer found.',
];

export const check = (error: string) => {
  return errors.includes(error);
};
