import { Button, Card, Col, Layout, Row, Select } from 'antd';
import React, { useState } from 'react';
import QrCodesBundles from './QrCodesBundles';
import { useStore } from '../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { Template } from '../../../app/models/templates/template';
import { IGetQuery } from '../../../app/api/Agent';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { QrCodeProjectSummaryDto } from '../../../app/models/qrcodes/qrCodeProjectSummary.dto';
import EmptyList from '../../../app/layout/EmptyList';
import { CodeTotalItem } from '../../../app/models/qrcodes/qrCodeSummary.dto';
import { CURRENCY_SYMBOLS } from '../../../app/common/options/constants';
import {
  getActiveSubscriptionId,
  navigate,
} from '../../../app/common/util/common';

interface Props {
  changeTab: (e: string) => void;
}

function QRCodesSummary(props: Props) {
  const { changeTab } = props;

  const { qrCodeStore, templatesStore, userStore } = useStore();
  const { getTemplates } = templatesStore;
  const {
    qrCodeSummary,
    loadingSummary,

    fetchQrCodeSummary,
  } = qrCodeStore;
  const { user } = userStore;

  const [templateFilter, setTemplateFilter] = useState<number>(-1);
  const activeSubscriptionId = getActiveSubscriptionId();

  function templateChanged(e: number) {
    if (!user || !activeSubscriptionId) return;

    let query: IGetQuery = {
      subscriptionId: activeSubscriptionId.toString(),
    };

    if (e !== -1) {
      query = {
        ...query,
        templateId: e.toString(),
      };
    }
    setTemplateFilter(e);
    fetchQrCodeSummary(query, false);
  }

  function goToPurchase() {
    if (!user) return;

    switch (user.subscriptionPackageId) {
      default:
      case 1:
        navigate('/account?tab=2');
        break;

      case 2:
      case 3:
        changeTab('2');
        break;
    }
  }

  const { Option } = Select;

  if (!qrCodeSummary)
    return (
      <EmptyList
        message="There is no QR Code summary data to show"
        buttonAction={() => {}}
        buttonText=""
        hideButton={true}
      />
    );

  return (
    <>
      <Layout className="qr-codes-summary">
        <div className="qr-codes-summary-header">
          <h3 className={'tab-header'}>QR Codes Summary</h3>

          {user!.subscriptionPackageId !== 1 && (
            <Button
              type={'primary'}
              className="qr-codes-button"
              onClick={() => changeTab('2')}>
              Purchase More QR Codes
            </Button>
          )}
        </div>

        <div className="qr-codes-container">
          <Card className="qr-codes-info-card">
            {loadingSummary ? (
              <LoadingComponent content="Loading summary details..." />
            ) : (
              <div className="qr-codes-remaining">
                <p className="qr-codes-remaining-amount">
                  {(user ?? { isSysAdmin: false }).isSysAdmin
                    ? qrCodeSummary?.totalNumberofQrCodeBought
                    : qrCodeSummary?.totalQRCodeNotScanned}
                </p>
                <p className="qr-codes-remaining-message">
                  {(user ?? { isSysAdmin: false }).isSysAdmin ? (
                    <p>Total number of QR Codes generated.</p>
                  ) : (
                    <>
                      This is the amount of QR codes you have left.{' '}
                      {(user ?? { subscriptionPackageId: 1 })
                        .subscriptionPackageId !== 1
                        ? 'Click the button below to purchase more.'
                        : 'Upgrade your subscription package to purchase more.'}
                    </>
                  )}
                </p>

                {!(user ?? { isSysAdmin: false }).isSysAdmin && (
                  <Button
                    className="qr-codes-card-button"
                    type={'primary'}
                    onClick={goToPurchase}>
                    {(user ?? { subscriptionPackageId: 1 })
                      .subscriptionPackageId !== 1
                      ? 'Buy More QR Codes'
                      : 'Upgrade package'}
                  </Button>
                )}
              </div>
            )}
          </Card>

          <Card className="qr-codes-info-card">
            <Select
              onChange={templateChanged}
              value={templateFilter}
              placeholder="Please select">
              <Option key={-1} value={-1}>
                All
              </Option>
              {getTemplates.map((t: Template) => (
                <Option value={t.id} key={t.id}>
                  {t.name}
                </Option>
              ))}
            </Select>

            {loadingSummary ? (
              <LoadingComponent content="Loading summary details..." />
            ) : (
              <>
                <div className="qr-codes-info-card-stats">
                  <Row>
                    <Col span={17}>Total QR Codes bought:</Col>
                    <Col span={7}>{qrCodeSummary?.totalQRCodeBought}</Col>
                  </Row>

                  <Row>
                    <Col span={17}>Total QR Codes downloaded:</Col>
                    <Col span={7}>{qrCodeSummary?.totalQrDownloaded}</Col>
                  </Row>

                  <Row>
                    <Col span={17}>QR codes scanned:</Col>
                    <Col span={7}>{qrCodeSummary?.photosScanned}</Col>
                  </Row>

                  <Row>
                    <Col span={17}>Customer engagement:</Col>
                    <Col span={7}>
                      {Math.round(qrCodeSummary?.customerEngagement)}%
                    </Col>
                  </Row>

                  <Row>
                    <Col span={17}>Photos shared / downloaded:</Col>
                    <Col span={7}>{qrCodeSummary?.totalShareIntentions}</Col>
                  </Row>

                  <Row>
                    <Col span={17}>Total revenue:</Col>
                    <Col span={7}>
                      {qrCodeSummary?.totals.length > 0
                        ? qrCodeSummary?.totals.map((total: CodeTotalItem) => (
                            <p key={total.currency}>
                              {CURRENCY_SYMBOLS[total.currency]}
                              {total.total}
                            </p>
                          ))
                        : 0}
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </Card>

          <Card
            title={'QR Codes Used Across Projects'}
            className="qr-codes-info-card across-project-card">
            {loadingSummary ? (
              <LoadingComponent content="Loading summary details..." />
            ) : (
              <div className="project-qr-codes">
                {qrCodeSummary.codesUsedAccrossProjects &&
                  qrCodeSummary.codesUsedAccrossProjects.length < 1 && (
                    <p>Currently there are no projects to show</p>
                  )}

                {qrCodeSummary.codesUsedAccrossProjects &&
                  qrCodeSummary.codesUsedAccrossProjects.map(
                    (v: QrCodeProjectSummaryDto) => (
                      <div
                        className="project-qr-codes-container"
                        key={v.projectId}>
                        <p className="project-qr-codes-project">
                          {v.projectName}
                        </p>
                        <p className="project-qr-codes-total">{v.totalCodes}</p>
                      </div>
                    )
                  )}
              </div>
            )}
          </Card>
        </div>

        <QrCodesBundles />
      </Layout>
    </>
  );
}

export default observer(QRCodesSummary);
