import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { PAYGATE_URLS } from '../../api/PaygateService';
import useQuery from '../util/hooks';

interface RedirectProps {
  requestId: string;
  checksum: string;
}

function PaygateRedirect() {
  const redirect = useRef<any>();
  const [details, setDetails] = useState<RedirectProps>({
    requestId: '',
    checksum: '',
  });

  const query = useQuery();
  useEffect(() => {
    setDetails({
      requestId: query.get('request') ?? '',
      checksum: query.get('checksum') ?? '',
    });
  }, []);

  useEffect(() => {
    if (
      redirect.current &&
      details.requestId.trim() !== '' &&
      details.checksum.trim() !== ''
    ) {
      redirect.current.submit();
    }
  }, [details]);

  return (
    <>
      <form action={PAYGATE_URLS.Redirect} method="POST" ref={redirect}>
        <input type="hidden" name="PAY_REQUEST_ID" value={details.requestId} />
        <input type="hidden" name="CHECKSUM" value={details.checksum} />
      </form>
    </>
  );
}

export default observer(PaygateRedirect);
