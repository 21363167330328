export const yyyymmdd = (startDate?: Date) => {
  const date = startDate ?? new Date();
  var mm = (date.getMonth() + 1).toString(); // getMonth() is zero-based
  var dd = date.getDate().toString();

  return [
    date.getFullYear(),
    mm.length === 2 ? '' : '0',
    mm,
    dd.length === 2 ? '' : '0',
    dd,
  ].join('');
};
