import { makeAutoObservable } from 'mobx';
import agent from '../api/Agent';
import { StripeCreatePaymentDto } from '../models/stripe/stripeCreatePayment.dto';
import { CreateStripeCustomerDto } from '../models/stripe/createStripeCustomer.dto';

export class StripeStore {
  constructor() {
    makeAutoObservable(this);
  }

  submitting: boolean = false;
  executing: boolean = false;

  setSubmitting = (value: boolean) => {
    this.submitting = value;
  };

  setExecuting = (value: boolean) => {
    this.executing = value;
  };

  create = async (
    amount: number,
    name: string,
    quantity?: number,
    currency?: string
  ) => {
    try {
      this.setSubmitting(true);
      const dto: StripeCreatePaymentDto = {
        amount,
        quantity,
        name,
        currency: currency ?? 'usd',
        successUrl: `${
          process.env.REACT_APP_PAYMENT_SUCCESS_REDIRECT ?? ''
        }?gateway=stripe&amount=${amount}${
          quantity == null ? '' : `&count=` + quantity
        }${currency == undefined ? '' : '&currency=' + currency}`,
        cancelUrl: `${
          process.env.REACT_APP_PAYMENT_FAIL_REDIRECT ?? ''
        }?gateway=stripe`,
      };
      const result = await agent.Stripe.create(dto);
      window.open(result.url, '_self');
    } catch (error) {
      throw error;
    } finally {
      this.setSubmitting(false);
    }
  };

  execute = async (quantity: number) => {
    try {
      this.setExecuting(true);

      await agent.Stripe.execute(quantity);
    } catch (error) {
      throw error;
    } finally {
      this.setExecuting(false);
    }
  };

  submittingCustomer = false;
  setSubmittingCustomer = (value: boolean) => {
    this.submittingCustomer = value;
  };

  createCustomer = async (dto: CreateStripeCustomerDto) => {
    try {
      this.setSubmittingCustomer(true);

      return await agent.Stripe.createCustomer(dto);
    } catch (error) {
      throw error;
    } finally {
      this.setSubmittingCustomer(false);
    }
  };

  getIntent = async (id: string) => {
    try {
      return await agent.Stripe.getIntent(id);
    } catch (error) {
      throw error;
    }
  };

  cancelSubscription = async (id: number) => {
    try {
      await agent.Stripe.cancelSub(id);
    } catch (error) {
      throw error;
    }
  };
}
