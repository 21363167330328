import { Collapse } from 'antd';
import React from 'react';
import WebsiteHeader from './WebsiteHeader';
import WebsiteSharing from './WebsiteSharing';
import WebsiteStyle from './WebsiteStyle';
import WebsiteFooter from './WebsiteFooter';

interface Props {
  readonly?: boolean;
}

function WebsiteSettings(props: Props) {
  const { Panel } = Collapse;
  return (
    <>
      <Collapse
        accordion
        expandIconPosition="right"
        className="project-website-collapse">
        <Panel header="Header" key="1">
          <WebsiteHeader />
        </Panel>

        <Panel header="Sharing" key="2">
          <WebsiteSharing />
        </Panel>

        <Panel header="Style" key="3">
          <WebsiteStyle />
        </Panel>

        <Panel header="Footer" key="4">
          <WebsiteFooter />
        </Panel>
      </Collapse>
    </>
  );
}

export default WebsiteSettings;
