import React, { useEffect } from 'react';
import { Menu, Image } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';

function SideBar() {
  // const [active, setActive] = useState('dashboard')
  const { navigationStore, paypalStore, userStore } = useStore();
  const { activePage, setActivePage, baseUrl } = navigationStore;
  const { paying } = paypalStore;
  const { user } = userStore;
  const { subId = undefined } = useParams<{ subId: string }>();
  const location = useLocation();

  function menuClick(e: any) {
    setActivePage(e.key);
  }

  useEffect(() => {
    setActivePage(
      location.pathname.includes(`/subscription/${subId}/`)
        ? location.pathname.split(`/subscription/${subId}/`)[1]
        : location.pathname.split('/')[1] || ''
    );
  }, [setActivePage, location.pathname, subId]);

  if (
    paying &&
    (location.pathname.includes('payment/success') ||
      location.pathname.includes('payment/fail') ||
      location.pathname.includes('stripe/redirect'))
  )
    return (
      <>
        <Menu
          // onClick={(e) => menuClick(e)}
          mode="vertical"
          className="sidebar"
          selectedKeys={[activePage]}>
          <Menu.Item
            key="header"
            className={'image-menu-option'}
            style={{ marginBottom: '20px', height: '90px' }}>
            <Image preview={false} src={`/assets/images/HSLogo.png`} />
          </Menu.Item>
        </Menu>
      </>
    );

  return (
    <>
      <Menu
        onClick={(e) => menuClick(e)}
        mode="vertical"
        className="sidebar"
        selectedKeys={[activePage]}>
        <Menu.Item
          key="header"
          className={'image-menu-option'}
          style={{ marginBottom: '20px', height: '90px' }}>
          <Link to="/">
            <Image preview={false} src={`/assets/images/HSLogo.png`} />
          </Link>
        </Menu.Item>

        <Menu.Item key="dashboard">
          <Link to={`${baseUrl}/dashboard`}>
            <Image
              preview={false}
              src={`/assets/images/dashboard${
                activePage === 'dashboard' ? '-active' : ''
              }.svg`}
              width={25}
              height={25}
            />
            Dashboard
          </Link>
        </Menu.Item>

        {(!user?.isSysAdmin || subId) && (
          <>
            <Menu.Item key="projects">
              <Link to={`${baseUrl}/projects`}>
                <Image
                  preview={false}
                  src={`/assets/images/projects${
                    activePage === 'projects' ? '-active' : ''
                  }.svg`}
                  width={25}
                  height={25}
                />
                Projects
              </Link>
            </Menu.Item>

            <Menu.Item key="templates">
              <Link to={`${baseUrl}/templates`}>
                <Image
                  preview={false}
                  src={`/assets/images/templates${
                    activePage === 'templates' ? '-active' : ''
                  }.svg`}
                  width={25}
                  height={25}
                />
                Templates
              </Link>
            </Menu.Item>
          </>
        )}

        <Menu.Item key="codes">
          <Link to={`${baseUrl}/codes`}>
            <Image
              preview={false}
              src={`/assets/images/qrcodes${
                activePage === 'codes' ? '-active' : ''
              }.svg`}
              width={25}
              height={25}
            />
            QR Codes
          </Link>
        </Menu.Item>

        <Menu.Item key="sales">
          <Link to={`${baseUrl}/sales`}>
            <Image
              preview={false}
              src={`/assets/images/sales${
                activePage === 'sales' ? '-active' : ''
              }.svg`}
              width={25}
              height={25}
            />
            Sales
          </Link>
        </Menu.Item>

        <Menu.Item key="team">
          <Link to={`${baseUrl}/team`}>
            <Image
              preview={false}
              src={`/assets/images/team${
                activePage === 'team' ? '-active' : ''
              }.svg`}
              width={25}
              height={25}
            />
            Team
          </Link>
        </Menu.Item>

        <Menu.Item key="account">
          <Link to={`${baseUrl}/account`}>
            <Image
              preview={false}
              src={`/assets/images/account${
                activePage === 'settings' ? '-active' : ''
              }.svg`}
              width={25}
              height={25}
            />
            Account
          </Link>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default observer(SideBar);
