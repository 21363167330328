import { Button, Checkbox, Space } from 'antd';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useStore } from '../../stores/store';
import { COOKIES } from '../options/constants';

function SafariMessage() {
  const { modalStore } = useStore();
  const { closeModal } = modalStore;

  const [hide, setHide] = useState<boolean>(false);

  function hideModal(e: any) {
    setHide(e.target.checked);
  }

  function closeClick() {
    if (hide) {
      Cookies.set(COOKIES.SAFARI_MESSAGE, 'hide');
    }
    closeModal();
  }

  return (
    <>
      <div className="sm-modal">
        <p>
          To download this file, please ensure that your Safari allows popups.{' '}
          <br />
          If Safari shows a message asking to continue, please click 'Allow' to
          get your download. See below to enable Safari popups for Happy Snappy
          Lite.
        </p>

        <img
          src="/assets/safari/blocked_message.png"
          alt="popup blocked image"
        />
        <img src="/assets/safari/blocked_icon.png" alt="popup blocked image" />
        <figcaption>Click icon next to the refresh button</figcaption>

        <Space size={20} direction="vertical">
          <Checkbox onChange={hideModal}>Don't show this modal again</Checkbox>

          <Button type="primary" onClick={closeClick}>
            Ok
          </Button>
        </Space>
      </div>
    </>
  );
}

export default SafariMessage;
