export const PaymentErrors = {
  paypal: [
    {
      code: 'order_not_confirmed',
      message: 'The payment has not been validated.',
    },
    {
      code: 'system_config_error',
      message:
        'There was an unexpected error, please try again or contact support.',
    },
    {
      code: 'invalid_payment_method',
      message: 'Invalid payment method, please try again.',
    },
    {
      code: 'payee_not_enabled_for_payment_method',
      message: 'You are unable to use this payment method.',
    },
    {
      code: 'processing_error',
      message:
        'There was an unexpected error, please try again or contact support.',
    },
    {
      code: 'min_amount_required_by_payment_method',
      message:
        "The transaction amount doesn't meet the minimum transaction requirement of the chosen payment method.",
    },
    {
      code: 'payment_method_error',
      message:
        'There was an unexpected error, please try again or contact support.',
    },
    {
      code: 'declined_by_payment_method',
      message: 'The transaction was declined by the payment method.',
    },
    {
      code: 'currency_not_supported_by_payment_method',
      message: 'The currency is not supported by the payment method.',
    },
    {
      code: 'country_not_supported_by_payment_method',
      message:
        'There was an unexpected error, please try again or contact support.',
    },
    {
      code: 'invalid_expiry_date',
      message:
        'There was an unexpected error, please try again or contact support.',
    },
    {
      code: 'unsupported_processing_instruction',
      message:
        'There was an unexpected error, please try again or contact support.',
    },
    {
      code: 'order_complete_on_payment_approval',
      message:
        'There was an unexpected error, please try again or contact support.',
    },
    {
      code: 'order_completion_in_progress',
      message:
        'There was an unexpected error, please try again or contact support.',
    },
    {
      code: 'internal_server_error',
      message:
        'There was an unexpected error, please try again or contact support.',
    },
    {
      code: 'payment_error',
      message:
        'There was an unexpected error, please try again or contact support.',
    },
  ],
  paygate: [
    {
      code: 'DATA_CHK',
      message:
        'There was an unexpected error, please try again or contact support.',
    },
  ],
  stripe: [],
  '': [],
};
