import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useStore } from '../../../../app/stores/store';
import { Button } from 'antd';

interface Props {
  subId: string;
}

function StripePayment(props: Props) {
  const { subId } = props;
  const { paypalStore } = useStore();
  const { setPaying } = paypalStore;

  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);

  async function handleSubmit(event: any) {
    event.preventDefault();
    setPaying(true);

    if (!stripe || !elements) {
      console.log('Not loaded');
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_STRIPE_REDIRECT}?subscription=${subId}`,
      },
    });

    if (error) {
      console.error(error);
      setPaying(false);
      setLoading(false);
    }
  }
  return (
    <>
      <form onSubmit={handleSubmit} className="payment-form">
        <PaymentElement />
        <Button
          type="primary"
          htmlType={'submit'}
          onClick={() => setLoading(true)}
          loading={loading}>
          Submit
        </Button>
      </form>
    </>
  );
}

export default observer(StripePayment);
