import React, { useEffect, useState } from 'react';
import { Button, Radio, RadioChangeEvent, Space, Tag } from 'antd';
import { DefaultWebsiteThemes } from '../../../../app/common/options/selectOptions';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { PlusOutlined } from '@ant-design/icons';
import { useStore } from '../../../../app/stores/store';
import { observer } from 'mobx-react-lite';

function WebsiteStyle() {
  const { templatesStore, projectStore } = useStore();
  const {
    templateWebsite,

    setTemplateWebsite,
  } = templatesStore;
  const { readonlySettings } = projectStore;

  const [selectedTheme, setSelectedTheme] = useState({
    background: '#ffffff',
    frame: '#e5e8ea',
    value: 'light',
  });
  const [colorPicker, setColorPicker] = useState({
    show: false,
    type: '',
    color: '',
  });
  const [colorChanged, setColorChanged] = useState(false);
  const [, setShowCustom] = useState(false);

  useEffect(() => {
    if (!templateWebsite) return;
    removeCustom();

    const themeIndex = DefaultWebsiteThemes.findIndex(
      (t) =>
        t.background.toLowerCase() ===
          templateWebsite.backgroundColor?.toLowerCase() &&
        t.frame.toLowerCase() === templateWebsite.photoFrameColor?.toLowerCase()
    );
    if (themeIndex !== -1) {
      const theme = DefaultWebsiteThemes[themeIndex];
      setSelectedTheme({
        background: theme.background,
        frame: theme.frame,
        value: theme.value,
      });
    } else {
      setSelectedTheme({
        background: templateWebsite.backgroundColor ?? '#ffffff',
        frame: templateWebsite.photoFrameColor ?? '#e5e8ea',
        value: 'custom',
      });
      setShowCustom(true);

      const custom = {
        key: '4',
        label: 'Custom:',
        value: 'custom',
        frame: templateWebsite.backgroundColor ?? '#ffffff',
        background: templateWebsite.photoFrameColor ?? '#e5e8ea',
      };
      DefaultWebsiteThemes.push(custom);
    }
  }, []);

  function removeCustom() {
    const custom = DefaultWebsiteThemes.findIndex(
      (theme) => theme.key === '4' && theme.value === 'custom'
    );
    if (custom === -1) return;

    DefaultWebsiteThemes.splice(custom, 1);
  }

  function changeTheme(e: RadioChangeEvent) {
    if (!e.target) return;

    const theme = DefaultWebsiteThemes.find((t) => t.value === e.target.value);
    if (!theme) return;

    setSelectedTheme({
      background: theme.background,
      frame: theme.frame,
      value: theme.value,
    });
    setTemplateWebsite({
      ...templateWebsite,
      backgroundColor: theme.background,
      photoFrameColor: theme.frame,
    });
    if (e.target.value !== 'custom') {
      setColorChanged(false);
    }
  }

  function openPicker(color: string, type: string) {
    if (readonlySettings) return;
    setColorPicker({
      show: true,
      type,
      color,
    });
  }

  function setColor(e: any) {
    let theme = selectedTheme;
    if (colorPicker.type === 'frame') {
      theme.frame = e;
    } else {
      theme.background = e;
    }

    setSelectedTheme({
      ...theme,
    });
    setColorPicker({
      ...colorPicker,
      color: e,
    });
    setColorChanged(true);
    createCustom();
  }

  function closePicker() {
    setColorPicker({
      show: false,
      type: '',
      color: '',
    });
  }

  function resetTheme() {
    setColorPicker({
      show: false,
      type: '',
      color: '',
    });
    setSelectedTheme({
      background: '#ffffff',
      frame: '#e5e8ea',
      value: 'light',
    });
    setTemplateWebsite({
      ...templateWebsite,
      backgroundColor: '#e5e8ea',
      photoFrameColor: '#ffffff',
    });
    setColorChanged(false);
    setShowCustom(false);
    const last = DefaultWebsiteThemes.length - 1;
    if (DefaultWebsiteThemes[last].value === 'custom') {
      DefaultWebsiteThemes.splice(last, 1);
    }
  }

  function createCustom() {
    setSelectedTheme({
      ...selectedTheme,
      value: 'custom',
    });
    setTemplateWebsite({
      ...templateWebsite,
      backgroundColor: selectedTheme.background,
      photoFrameColor: selectedTheme.frame,
    });
    setShowCustom(true);

    const custom = {
      key: '4',
      label: 'Custom:',
      value: 'custom',
      frame: selectedTheme.frame,
      background: selectedTheme.background,
    };
    const last = DefaultWebsiteThemes.length - 1;
    if (DefaultWebsiteThemes[last].value !== 'custom') {
      DefaultWebsiteThemes.push(custom);
    } else {
      DefaultWebsiteThemes[last] = custom;
    }
  }

  return (
    <>
      <div style={{ display: 'flex' }} className="website-header-subheading">
        <span>Colour Themes</span>
        <Button
          disabled={readonlySettings}
          style={{ marginLeft: 'auto' }}
          type="text"
          onClick={resetTheme}>
          Reset
        </Button>
      </div>

      <Radio.Group
        value={selectedTheme.value}
        style={{ marginTop: '15px' }}
        defaultValue={'light'}
        onChange={(e: RadioChangeEvent) => changeTheme(e)}>
        {DefaultWebsiteThemes.map((theme) => (
          <Radio
            key={theme.key}
            value={theme.value}
            style={{ marginBottom: '15px' }}
            disabled={readonlySettings}>
            <div className="website-theme-option">
              <div className="website-theme-option-label">{theme.label}</div>
              <Tag color={theme.background} />
              <Tag color={theme.frame} />
            </div>
          </Radio>
        ))}
      </Radio.Group>

      <div className="website-header-subheading">Edit Theme</div>

      <div className="website-theme-selected">
        <p>Background Color: </p>
        <Tag
          onClick={(e) => openPicker(selectedTheme.background, 'background')}
          color={selectedTheme.background}
          style={{
            color: `${selectedTheme.value === 'light' ? 'black' : 'white'}`,
          }}>
          {readonlySettings ? '' : 'Click to change'}
        </Tag>
      </div>
      <div className="website-theme-selected">
        <p>Frame Color: </p>
        <Tag
          onClick={(e) => openPicker(selectedTheme.frame, 'frame')}
          color={selectedTheme.frame}
          style={{
            color: `${selectedTheme.value === 'light' ? 'black' : 'white'}`,
          }}>
          {readonlySettings ? '' : 'Click to change'}
        </Tag>
      </div>
      {colorPicker.show && (
        <div className={'theme-color'}>
          <HexColorPicker
            color={colorPicker.color}
            onChange={setColor}
            className="theme-color-picker"
          />
          <Space size={10} direction="vertical" style={{ margin: 'auto 20px' }}>
            <HexColorInput
              color={colorPicker.color}
              onChange={setColor}
              className={'ant-input theme-color-input'}
              prefixed
            />
            <Space>
              <Button
                type="primary"
                style={{ width: '80px' }}
                onClick={closePicker}>
                Close
              </Button>
            </Space>
          </Space>
        </div>
      )}
    </>
  );
}

export default observer(WebsiteStyle);
