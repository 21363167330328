import { makeAutoObservable } from 'mobx';
import { TitleLink, TopNavLinks } from '../common/options/topNavLinks';

export default class NavigationStore {
  pageTitle: string = '';
  topNavLinks: TitleLink[] = [];
  id: string = '';
  activePage = '';
  baseUrl = '';

  constructor() {
    makeAutoObservable(this);
  }

  setPageTitle = (title: string) => {
    this.pageTitle = title;
    this.setTopNavLinks(title);
  };

  setTopNavLinks = (key: string, id?: string) => {
    if (TopNavLinks[key]) {
      this.topNavLinks = TopNavLinks[key];
      if (id) this.id = id;
    } else {
      this.topNavLinks = [];
    }
  };

  setActivePage = (page: string) => {
    this.activePage = page;
  };

  setBaseUrl = (link: string) => {
    this.baseUrl = link;
  };
}
