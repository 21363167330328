import { makeAutoObservable } from 'mobx';
import PaygateService from '../api/PaygateService';
import { AxiosError } from 'axios';
import agent from '../api/Agent';

interface PaygateResponse {
  PAYGATE_ID: string;
  PAY_REQUEST_ID: string;
  REFERENCE: string;
  CHECKSUM: string;
}

export class PaygateStore {
  constructor() {
    makeAutoObservable(this);
  }

  submitting: boolean = false;
  executing: boolean = false;

  setSubmitting = (value: boolean) => {
    this.submitting = value;
  };

  setExecuting = (value: boolean) => {
    this.executing = value;
  };

  create = async (amount: number, quantity: number, email: string) => {
    try {
      this.setSubmitting(true);

      const result: string = await PaygateService.startPayment(
        amount,
        quantity,
        email
      );
      const response = {} as PaygateResponse;
      const split = result.split('&');
      split.forEach((s: string) => {
        const items = s.split('=');
        Reflect.set(response, items[0], items[1]);
      });

      window.open(
        `/paygate/redirect?request=${response.PAY_REQUEST_ID}&checksum=${response.CHECKSUM}`,
        '_blank'
      );
    } catch (error) {
      console.log(error as AxiosError);
      // throw error;
    } finally {
      this.setSubmitting(false);
    }
  };

  execute = async (amount: number) => {
    try {
      this.setExecuting(true);

      await agent.Paygate.execute(amount);
    } catch (error) {
      throw error;
    } finally {
      this.setExecuting(false);
    }
  };
}
