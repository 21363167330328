import { Layout, Card, Form, Input, Button } from 'antd';
import React, { useState } from 'react';
import PasswordReset from './PasswordReset';
import { useStore } from '../../../app/stores/store';
import { ResetRequestDto } from '../../../app/models/resetRequest.dto';
import { observer } from 'mobx-react-lite';

interface Props {
  resetKey: string;
}

interface FormFields {
  password: string;
  password_repeat: string;
  email: string;
}

function EnterPasswords(props: Props) {
  const { resetKey } = props;
  const { accountStore } = useStore();
  const { submitting, resetPassword } = accountStore;

  const [passwordReset, setPasswordReset] = useState(false);

  async function onPasswordFinish(values: FormFields) {
    const dto: ResetRequestDto = {
      newPassword: values.password,
      newConfirmPassword: values.password_repeat,
      email: values.email,
      token: resetKey,
    };

    await resetPassword(dto);
    setPasswordReset(true);
  }

  if (passwordReset) return <PasswordReset />;

  return (
    <>
      <Layout className="forgot-password">
        <Card className="forgot-password-card">
          <p>Please enter your email address and choose a new password.</p>

          <Form
            name="enterEmail"
            layout="vertical"
            onFinish={onPasswordFinish}
            autoComplete="off"
            requiredMark="optional">
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Enter email.' }]}>
              <Input placeholder="Enter email" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please enter new password.' },
                { min: 6, message: 'Password must be 6 characters long.' },
                {
                  pattern: new RegExp('^(?=.*[A-Z])'),
                  message: 'Password must contain 1 capital letter',
                },
                {
                  pattern: new RegExp('^(?=.*?[0-9])'),
                  message: 'Password must contain 1 number',
                },
              ]}>
              <Input.Password placeholder="Enter password" />
            </Form.Item>

            <Form.Item
              name="password_repeat"
              rules={[
                { required: true, message: 'Please repeat the password.' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match!'
                      )
                    );
                  },
                }),
              ]}>
              <Input.Password placeholder="Repeat password" />
            </Form.Item>

            <Button loading={submitting} type="primary" htmlType="submit">
              Reset password
            </Button>
          </Form>
        </Card>
      </Layout>
    </>
  );
}

export default observer(EnterPasswords);
