import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/Agent';
import { AddMemberDto } from '../models/team/AddMemberDto';
import { DeleteUserDto } from '../models/team/DeleteUserDto';
import { EditTeamMemberDto } from '../models/team/editTeamMembersDto';
import { TeamMember } from '../models/team/teamMember';
import { toast } from 'react-toastify';

export class TeamStore {
  deleting: boolean = false;
  submitting: boolean = false;
  loading: boolean = false;

  selectedMember: TeamMember | null = null;
  memberRoles: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setDeleting = (value: boolean) => {
    this.deleting = value;
  };

  setSubmitting = (value: boolean) => {
    this.submitting = value;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  deleteUser = async (dto: DeleteUserDto) => {
    try {
      this.setDeleting(true);

      await agent.Team.deleteUser(dto);
    } catch (error) {
      throw error;
    } finally {
      this.setDeleting(false);
    }
  };

  addMember = async (dto: AddMemberDto) => {
    try {
      this.setSubmitting(true);

      const result = await agent.Team.addMember(dto);
      return result;
    } catch (error) {
      toast.error(
        `Unable to create user because: ${(error as string[]).join(', ')}`
      );
      throw error;
    } finally {
      this.setSubmitting(false);
    }
  };

  getMember = async (subscriptionId: number, userId: number) => {
    try {
      this.setLoading(true);

      const result = await agent.Team.getMember(subscriptionId, userId);
      runInAction(() => {
        this.selectedMember = result;
        this.memberRoles = [];
        if (result.isAdministrator) this.memberRoles.push('admin');
        if (result.isManager) this.memberRoles.push('manager');
        if (result.isPhotographer) this.memberRoles.push('photographer');
      });
      return result;
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false);
    }
  };

  setSelectedMember = (value: TeamMember | null) => {
    this.selectedMember = value;
  };

  editMember = async (
    subscriptionId: number,
    userId: number,
    dto: EditTeamMemberDto
  ) => {
    try {
      this.setSubmitting(true);

      await agent.Team.editMember(subscriptionId, userId, dto);
      toast.success('Saved member details!');
    } catch (error) {
      throw error;
    } finally {
      this.setSubmitting(false);
    }
  };
}
