import { Button, Card, Form, Input } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../../app/stores/store';
import Utf8 from 'crypto-js/enc-utf8';
import DES from 'crypto-js/tripledes';
import Hex from 'crypto-js/enc-hex';
import Base64 from 'crypto-js/enc-base64';

interface Props {
  changeMode: () => void;
}

const { TextArea } = Input;

function SalesEditGateway(props: Props) {
  const { changeMode } = props;
  const { salesStore } = useStore();
  const { selectedGateway, setSelectedGateway } = salesStore;

  const [form] = Form.useForm();
  function updateGateway() {
    if (!selectedGateway) return;
    setSelectedGateway({
      ...selectedGateway,
      clientKey: form.getFieldValue('clientSecret'),
      firtsName: form.getFieldValue('name'),
      lastName: form.getFieldValue('surname'),
      email: form.getFieldValue('email'),
      clientId: form.getFieldValue('clientId'),
    });
  }
  if (!selectedGateway) {
    changeMode();
    return <></>;
  }

  return (
    <>
      <Card>
        <Form
          form={form}
          onBlur={updateGateway}
          style={{ width: '500px' }}
          initialValues={{
            name: selectedGateway.firtsName,
            surname: selectedGateway.lastName,
            email: selectedGateway.email,
            clientId: selectedGateway.clientId,
            clientSecret: selectedGateway.clientKey,
          }}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          autoComplete="off"
          name="edit-gateway-form">
          <Form.Item
            name="name"
            label="First Name:"
            rules={[{ required: true, message: 'This field is required' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="surname"
            label="Last Name:"
            rules={[{ required: true, message: 'This field is required' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name={'email'}
            label="Email:"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'This is not a valid email',
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item name="clientId" label="Gateway ID:">
            <TextArea
              rows={5}
              disabled={selectedGateway.gatewayName === 'stripe'}
            />
          </Form.Item>
          <Form.Item
            label={'Secret Key:'}
            name={'clientSecret'}
            rules={[
              { required: true, message: 'Please input your password!' },
            ]}>
            <TextArea rows={5} />
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default observer(SalesEditGateway);
