import React from 'react';
import { Avatar as AntAvatar, Tooltip } from 'antd';
import { getUserNameInitials } from '../../../app/common/util/common';

type Props = {
  username?: string;
  index?: number;
  allowTooltip?: boolean;
};

const Avatar = ({ username, index = 0, allowTooltip = true }: Props) => {
  return (
    <Tooltip title={allowTooltip && username} placement="top">
      <AntAvatar
        style={{
          ...calculateColors(index),
          borderWidth: '1px',
          borderStyle: 'solid',
          fontWeight: 'bold',
          fontSize: '14px',
          flexShrink: 0,
        }}
        size={35}>
        {getUserNameInitials(username, 2)}
      </AntAvatar>
    </Tooltip>
  );
};

const calculateColors = (index: number) => {
  switch (index % 4) {
    case 0:
      return {
        color: 'var(--primary-blue)',
        borderColor: 'var(--primary-blue)',
        backgroundColor: 'var(--primary-blue-light)',
      };
    case 1:
      return {
        color: 'var(--primary-yellow)',
        borderColor: 'var(--primary-yellow)',
        backgroundColor: 'var(--primary-yellow-light)',
      };
    case 2:
      return {
        color: 'var(--primary-dark-blue)',
        borderColor: 'var(--primary-dark-blue)',
        backgroundColor: 'var(--primary-purple-light)',
      };
    default:
      return {
        color: 'var(--primary-orange)',
        borderColor: 'var(--primary-orange)',
        backgroundColor: 'var(--primary-orange-light)',
      };
  }
};

export default Avatar;
