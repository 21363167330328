import { Collapse } from 'antd';
import React from 'react';
import TemplateCommerce from './TemplateCommerce';
import TemplateLayers from './TemplateLayers';
import TemplateSettings from './TemplateSettings';
import { useStore } from '../../../app/stores/store';

const { Panel } = Collapse;

function TemplateEditorMenus() {
  const { userStore } = useStore();

  const { user } = userStore;

  return (
    <>
      <Collapse
        accordion
        expandIconPosition="right"
        className="editor-collapse">
        <Panel header="Template Settings" key="1">
          <TemplateSettings />
        </Panel>

        <Panel header="Image Layers" key="2">
          <TemplateLayers />
        </Panel>

        {(user ?? { subscriptionPackageId: 1 }).subscriptionPackageId !== 1 && (
          <>
            <Panel header="Online Sales Settings" key="3">
              <TemplateCommerce />
            </Panel>
          </>
        )}
      </Collapse>
    </>
  );
}

export default TemplateEditorMenus;
