import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Col, Layout, Row } from 'antd';
import useQuery from '../../app/common/util/hooks';
import { useStore } from '../../app/stores/store';
import { history } from '../../index';
import { InfoCircleOutlined } from '@ant-design/icons';

interface PageDetails {
  gateway: string;
  amount: string;
  count: string;
  currency: string;
  paymentId: string;
  token: string;
  payerId: string;
}

interface RequestDetails {
  total: string;
  subId: string;
  output: string;
  description: string;
}

function PaymentSuccess() {
  const { paypalStore, stripeStore, qrCodeStore } = useStore();
  const { setPaying } = paypalStore;
  const { executing, execute } = stripeStore;
  const { requestCodes, generatingBundle } = qrCodeStore;

  const [details, setDetails] = useState<PageDetails>({
    gateway: '',
    amount: '',
    count: '',
    paymentId: '',
    currency: '',
    token: '',
    payerId: '',
  });
  const [requestDetails, setRequestDetails] = useState<RequestDetails>({
    total: '',
    subId: '',
    description: '',
    output: '',
  });

  const query = useQuery();
  useEffect(() => {
    setDetails({
      gateway: query.get('gateway') ?? '',
      amount: query.get('amount') ?? '',
      count: query.get('count') ?? '',
      currency: query.get('currency') ?? '',
      paymentId: query.get('paymentId') ?? '',
      token: query.get('token') ?? '',
      payerId: query.get('PayerID') ?? '',
    });
    setPaying(true);

    const requestString = localStorage.getItem('CODE_REQUEST');
    if (!requestString) {
      setRequestDetails({
        total: 'error',
        description:
          'There was an error getting the album code request. Once the payment has been completed please navigate to the QR Codes page and try generate a bundle again.',
        output: '',
        subId: '',
      });
      return;
    }

    const split = requestString.split(';');
    const initial = {
      total: '',
      subId: '',
      description: '',
      output: '',
    };
    split.forEach((s: string) => {
      const details = s.split('=');
      Reflect.set(initial, details[0], details[1]);
    });
    setRequestDetails(initial);
  }, [setDetails]);

  // Todo: We need to make sure that the amount is stored in the Database so if the user doesnt click continue we can still generate the codes when they come back
  async function complete() {
    await execute(+details.count);
    await requestCodes(
      requestDetails.total,
      requestDetails.output,
      requestDetails.description,
      requestDetails.subId
    );

    localStorage.removeItem('CODE_REQUEST');
    history.push('/codes');
  }

  const { Header, Content } = Layout;
  return (
    <>
      <Layout>
        <Header className="view-project-header">
          <div>
            <h2>Successful Payment</h2>
          </div>
        </Header>

        <Content className="payment-success">
          <div>You have successfully made a payment for the following:</div>

          <div className="payment-success-details">
            <Row>
              <Col span={7}>
                <strong>Amount:</strong>
              </Col>
              <Col span={12}>
                {details.amount} ({details.currency ?? 'USD'})
              </Col>
            </Row>

            <Row>
              <Col span={7}>
                <strong>Number of codes:</strong>
              </Col>
              <Col span={12}>{details.count}</Col>
            </Row>

            <Row>
              <Col span={7}>
                <strong>Gateway:</strong>
              </Col>
              <Col span={12}>
                <img
                  src={`/assets/images/${details.gateway}.png`}
                  alt={'payment gateway logo'}
                />
              </Col>
            </Row>
          </div>

          <Button
            loading={executing || generatingBundle}
            type="primary"
            onClick={complete}>
            Continue
          </Button>
          {generatingBundle && (
            <div className="generate-code-message">
              <InfoCircleOutlined />
              <p>
                Generating QR Codes may take some time. Please stay on this page
                until generation is complete.
              </p>
            </div>
          )}
        </Content>
      </Layout>
    </>
  );
}

export default observer(PaymentSuccess);
