import { Button, Input, Tooltip } from 'antd';
import React from 'react';

type Props = {
  label?: string;
  buttonText: string;
  value: string;
  onClick: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  buttonDisabled?: boolean;
  inputDisabled?: boolean;
  buttonTooltip?: string;
  placeholder?: string;

  className?: string;
  inputClassName?: string;
  buttonClassName?: string;
  labelClassName?: string;
};

const ProjectPhotosFilterInputButtonCombo = ({
  label,
  buttonText,
  value,
  onClick,
  onChange,
  buttonDisabled = false,
  inputDisabled = false,
  buttonTooltip,
  placeholder,
  className,
  inputClassName,
  buttonClassName,
  labelClassName,
}: Props) => {
  return (
    <div className={`pf-option ${className || ''}`}>
      <label>
        {label && (
          <p className={`pf-option__label ${labelClassName || ''}`}>{label}</p>
        )}
        <Input
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          className={`pf-input-button-combo-input ${inputClassName || ''}`}
          disabled={inputDisabled}
        />
      </label>
      <Tooltip title={buttonTooltip}>
        <Button
          className={`pf-input-button-combo-button ${buttonClassName || ''}`}
          onClick={onClick}
          type="primary"
          disabled={buttonDisabled}>
          {buttonText}
        </Button>
      </Tooltip>
    </div>
  );
};

export default ProjectPhotosFilterInputButtonCombo;
