import React, { useState } from 'react';
import { Checkbox, Form, Input } from 'antd';
import { useStore } from '../../../app/stores/store';
import { observer } from 'mobx-react-lite';

function TemplateSettings() {
  const { templatesStore, fabricStore, userStore } = useStore();

  const {
    selectedTemplate,
    selectedImageSet,

    setDisableSubmitSettings,
    setSelectedTemplate,
  } = templatesStore;

  const { user } = userStore;

  const [showMessage, setShowMessage] = useState(false);

  const [form] = Form.useForm();
  function leaveForm() {
    if (!selectedTemplate) return;

    setSelectedTemplate({
      ...selectedTemplate,
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
      isGreenScreen: form.getFieldValue('isGreenScreen') ?? false,
    });

    if (form.getFieldValue('isGreenScreen')) {
      setShowMessage(true);
      fabricStore.setCanvasBackground('transparent');
    } else {
      setShowMessage(false);
      fabricStore.setCanvasBackground('white');
    }

    setDisableSubmitSettings(
      !form.getFieldValue('name') ||
        form.getFieldValue('name').trim() === '' ||
        !form.getFieldValue('description') ||
        form.getFieldValue('description').trim() === '' ||
        (form.getFieldValue('isGreenScreen') &&
          (selectedImageSet!.templateImages[0].backgroundUrl === '' ||
            selectedImageSet!.templateImages[1].backgroundUrl === ''))
    );
  }

  function setIsGreenScreen() {
    const curValue = form.getFieldValue('isGreenScreen');
    if (!curValue) {
      form.setFieldsValue({ isGreenScreen: true });
    } else {
      form.setFieldsValue({ isGreenScreen: false });
    }
    leaveForm();
  }

  return (
    <>
      <Form
        className="add-template-form"
        name="addTemplate"
        layout="vertical"
        autoComplete="off"
        initialValues={selectedTemplate!}
        requiredMark={false}
        form={form}
        onValuesChange={leaveForm}>
        <Form.Item
          label="Template name"
          name="name"
          rules={[{ required: true, message: 'Please add a template name' }]}>
          <input className="ant-input" />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            { required: true, message: 'Please add a template description.' },
          ]}>
          <Input.TextArea />
        </Form.Item>

        {(user ?? { subscriptionPackageId: 1 }).subscriptionPackageId !== 1 && (
          <>
            <Form.Item style={{ marginBottom: '0' }}>
              <Form.Item valuePropName="checked" noStyle name="isGreenScreen">
                <Checkbox />
              </Form.Item>
              <label
                style={{ marginLeft: '10px', cursor: 'pointer' }}
                onClick={setIsGreenScreen}>
                Remove Background
              </label>
            </Form.Item>
            <p className={'add-template-form-greenscreen'}>
              {showMessage &&
                'Please upload a portrait and landscape background image before saving!'}
            </p>
          </>
        )}
      </Form>
    </>
  );
}

export default observer(TemplateSettings);
