import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import CodesRemaining from './components/CodesRemaining';
import { Layout, Row } from 'antd';
import StatsCard from './components/StatsCard';
import { useStore } from '../../app/stores/store';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { yyyymmdd } from '../../app/common/util/date';
import { Header } from 'antd/lib/layout/layout';
import {
  getActiveSubscriptionId,
  getActiveSubscriptionName,
} from '../../app/common/util/common';

const STATS_CARDS = ['templates', 'shares', 'photographers', 'projects'];

function Dashboard() {
  const { userStore, dashboardStore, accountStore, subscriptionsStore } =
    useStore();
  const { user } = userStore;
  const {
    qrCodes,
    loading,
    loadingComponent,
    shareArray,
    photographers,
    projectPhotos,
    templatePhotos,
    getOverall,
    getTemplateShares,
    getActivePhotographers,
    getActiveTemplates,
    getProjectPhotos,
  } = dashboardStore;
  const activeSubscriptionId = getActiveSubscriptionId();

  useEffect(() => {
    if (!user || !activeSubscriptionId) return;

    getOverall(activeSubscriptionId, true);
  }, [getOverall, activeSubscriptionId, user]);

  function filterCard(e: string, card: string) {
    if (!user) return;

    const lowerDate = new Date();
    switch (e) {
      case '7_days':
        lowerDate.setDate(lowerDate.getDate() - 7);
        break;

      case '14_days':
        lowerDate.setDate(lowerDate.getDate() - 14);
        break;

      case '30_days':
        lowerDate.setDate(lowerDate.getDate() - 30);
        break;
    }
    const lowerDateString = e === 'all' ? '20000101' : yyyymmdd(lowerDate);

    if (!activeSubscriptionId) return;

    switch (card) {
      case 'shares':
        getTemplateShares(
          activeSubscriptionId,
          lowerDateString,
          yyyymmdd(new Date())
        );
        break;

      case 'photographers':
        getActivePhotographers(
          activeSubscriptionId,
          lowerDateString,
          yyyymmdd(new Date())
        );
        break;

      case 'templates':
        getActiveTemplates(
          activeSubscriptionId,
          lowerDateString,
          yyyymmdd(new Date())
        );
        break;

      case 'projects':
        getProjectPhotos(
          activeSubscriptionId,
          lowerDateString,
          yyyymmdd(new Date())
        );
        break;
    }
  }

  if (loading && loadingComponent.length === 4)
    return <LoadingComponent content="Loading Dashboard..." />;

  return (
    <>
      <Layout className="dashboard-container">
        <Header className="view-project-header dashboard-header">
          <div>
            <h2>
              Welcome <strong>{getActiveSubscriptionName()}</strong>
              {user?.isSysAdmin ? ' (System Admin)' : ''}
            </h2>
          </div>
        </Header>

        <CodesRemaining codes={qrCodes ? qrCodes.qr_Code_Remaining : 0} />

        <Row style={{ rowGap: '26px' }}>
          <StatsCard
            name="templates"
            filterTime={filterCard}
            data={templatePhotos}
            type="bar"
            title="Most Active Templates"
          />
          <StatsCard
            name="shares"
            filterTime={filterCard}
            data={shareArray}
            type="pie"
            title="Social Shares (All Templates)"
          />
        </Row>

        <Row style={{ marginTop: '26px', rowGap: '26px' }}>
          <StatsCard
            name="photographers"
            filterTime={filterCard}
            data={photographers}
            type="table"
            title="Most Active Photographers"
          />
          <StatsCard
            name="projects"
            filterTime={filterCard}
            data={projectPhotos}
            type="bar"
            title="Most Active Projects"
          />
        </Row>
      </Layout>
    </>
  );
}

export default observer(Dashboard);
