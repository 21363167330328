import React, { useState } from 'react';
import { useStore } from '../../../app/stores/store';
import { Button, Dropdown, Layout, Menu, Table } from 'antd';
import EmptyList from '../../../app/layout/EmptyList';
import { observer } from 'mobx-react-lite';
import { Template } from '../../../app/models/templates/template';
import { history } from '../../../index';
import { EntityStatus } from '../../../app/common/options/enums';
import { TemplateImageSetFull } from '../../../app/models/templates/templateImageSetFull';
import { MoreOutlined } from '@ant-design/icons';
import ProjectTemplatesAdd from './ProjectTemplatesAdd';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import DeleteConfirmationModal from '../../../app/common/modals/DeleteConfirmationModal';
import TableImagePreview from '../../../app/common/components/TableImagePreview';
import AddTemplateForm from '../../templates/components/AddTemplateForm';
import { yyyymmdd } from '../../../app/common/util/date';

function ProjectTemplates() {
  const {
    projectStore,
    modalStore,
    templatesStore,
    userStore,
    navigationStore,
  } = useStore();
  const {
    selectedProject,
    addingTemplate,
    projectTemplates,
    submitting,
    loadingTemplates,
    loadingPhotos,

    setAddingTemplate,
    removeProjectTemplate,
    addTemplates,
    fetchProjectTemplates,
    getPhotos,
  } = projectStore;
  const { openModal, closeModal } = modalStore;
  const { getTemplatesBySubscription, getTemplates, loading } = templatesStore;
  const { user } = userStore;

  function goToTemplate(id: number) {
    history.push(`${navigationStore.baseUrl}/templates/${id}`);
  }

  async function removeTemplate(record: Template) {
    if (!selectedProject) return;

    const response = await removeProjectTemplate(record.id);
    if (response && response.message === 'photos') {
      const deleteMessage = (
        <p>
          Photos have been uploaded to this template and project in the last 6
          months. Therefore this template cannot be removed from this project.
        </p>
      );

      openModal(
        true,
        <DeleteConfirmationModal
          singleButton={true}
          cancel={closeModal}
          confirm={closeModal}
          message={<p>{deleteMessage}</p>}
        />,
        'Deleting Project',
        true,
        'delete-confirmation'
      );
    }
  }

  const [buttonType, setButtonType] = useState<'show_menu' | 'add_list'>(
    'show_menu'
  );
  const [selectedTemplates, setSelectedTemplates] = useState<any[]>([]);
  function addTemplateArray(selectedKeys: React.Key[]) {
    setSelectedTemplates(selectedKeys);
  }

  async function addTemplate() {
    if (buttonType === 'show_menu') {
      await checkList();
      return;
    }

    await addTemplates(selectedProject!.id.toString(), {
      templateIds: selectedTemplates,
    });
    const endDate = yyyymmdd(new Date());
    await getPhotos(selectedProject!.id, '20000101', endDate, 1);
    setAddingTemplate(false);
    setButtonType('show_menu');
    fetchProjectTemplates();
  }

  async function checkList() {
    if (!user) return;

    const result = await getTemplatesBySubscription(
      user!.subscriptionId.toString(),
      {
        filter: 'active',
        mode: 'full',
      }
    );
    if (getTemplates.length > 0 || (result ?? 0) > 0) {
      setAddingTemplate(true);
    } else {
      openModal(
        true,
        <AddTemplateForm
          from="projects"
          project_id={selectedProject!.id}
          type="all"
        />,
        'Create New Template',
        true
      );
    }
  }

  const columns = [
    {
      title: 'Preview',
      dataIndex: 'sampleImageUrl',
      key: 'sample_url',
      render: (sampleImageUrl: string) => (
        <>
          <TableImagePreview url={sampleImageUrl} />
        </>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, record: Template) => (
        <div
          className="project-templates-table-name"
          onClick={() => goToTemplate(record.id)}>
          {name}
        </div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'isGreenScreen',
      key: 'isGreenScreen',
      render: (isGreenScreen: boolean) => (
        <div>{isGreenScreen ? 'No Background' : 'Standard'}</div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: number) => <div>{EntityStatus[status]}</div>,
    },
    {
      title: 'Variations',
      dataIndex: 'templateImageSets',
      key: 'templateImagesSets',
      render: (templateImageSets: TemplateImageSetFull[]) => (
        <div>{templateImageSets.length}</div>
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      width: '50px',
      render: (id: number, record: Template) => {
        const menu = (
          <Menu>
            <Menu.Item key="0" onClick={() => removeTemplate(record)}>
              Remove
            </Menu.Item>
          </Menu>
        );
        return (
          <>
            <Dropdown
              overlay={menu}
              trigger={['click']}
              placement="bottomRight"
              overlayStyle={{ width: '150px' }}>
              <MoreOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
            </Dropdown>
          </>
        );
      },
    },
  ];

  function cancelList() {
    setAddingTemplate(false);
    setButtonType('show_menu');
  }

  const { Header, Content } = Layout;
  return (
    <>
      <Layout className="project-templates">
        <Header>
          <h3 className={'tab-header'}>Project Templates</h3>
          {buttonType === 'add_list' && (
            <Button
              danger
              style={{ marginLeft: 'auto' }}
              type="text"
              onClick={cancelList}>
              Cancel
            </Button>
          )}
          <Button
            type="primary"
            onClick={addTemplate}
            loading={submitting || loadingPhotos || loading}>
            {buttonType === 'show_menu'
              ? 'Add template'
              : 'Add selected templates'}
          </Button>
        </Header>

        <Content>
          {addingTemplate ? (
            <ProjectTemplatesAdd
              callBack={addTemplateArray}
              menuOption={setButtonType}
            />
          ) : projectTemplates && projectTemplates.length > 0 ? (
            <>
              <Table
                loading={submitting}
                rowKey={'id'}
                columns={columns}
                dataSource={projectTemplates.filter(
                  (t) => t.status !== EntityStatus.Deleted
                )}
                className="project-templates-table"
              />
            </>
          ) : (
            <>
              {loadingTemplates ? (
                <LoadingComponent content="Loading Project Templates..." />
              ) : (
                <EmptyList
                  message="This project has no templates, click on the button to add one"
                  buttonText="Add template"
                  buttonLoading={loading}
                  buttonAction={checkList}
                />
              )}
            </>
          )}
        </Content>
      </Layout>
    </>
  );
}

export default observer(ProjectTemplates);
