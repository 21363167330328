import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { history } from '../..';
import { User, UserFormValues } from '../models/user';
import { store } from '../stores/store';
import { LoginDTO } from '../models/LoginDTO';
import { Template } from '../models/templates/template';
import { TemplateCreateDTO } from '../models/templates/templateCreateDTO';
import { TemplateSettingsDTO } from '../models/templates/templateSettingsDTO';
import { TemplateCurrencyDTO } from '../models/templates/templateCurrencyDTO';
import { TemplateImageSetFull } from '../models/templates/templateImageSetFull';
import { PaymentGateway } from '../models/paymentGateway';
import { TemplateInfoDTO } from '../models/templates/templateInfoDTO';
import { check } from './listErrors';
import { SubscriptionDto } from '../models/subscriptions/subscriptionDto';
import { TemplateWebsite } from '../models/templates/templateWebsite';
import { TemplateDataCollection } from '../models/templates/templateDataCollection';
import { TemplateImageSetPutDTO } from '../models/templates/templateImageSetPut.dto';
import { ProjectPostDTO } from '../models/projects/projectPostDTO';
import { ProjectDTO } from '../models/projects/projectDto';
import { ProjectPutDTO } from '../models/projects/projectPutDTO';
import { ProjectAddTemplate } from '../models/projects/projectAddTemplate';
import { AppUser } from '../models/appUser';
import { ProjectUser } from '../models/projects/projectUser';
import { UserDetails } from '../models/subscriptions/userDetails';
import { DeleteUserDto } from '../models/team/DeleteUserDto';
import { AddMemberDto } from '../models/team/AddMemberDto';
import { TeamMember } from '../models/team/teamMember';
import { EditTeamMemberDto } from '../models/team/editTeamMembersDto';
import { SubscriptionFullDto } from '../models/subscriptions/subscriptionFullDto';
import { EditSubscriptionUser } from '../models/subscriptions/editSubscriptionUser';
import { SubscriptionCurrencyUpdateDto } from '../models/currencies/subscriptionCurrencyUpdate.dto';
import { TransactionsFilter } from '../models/sales/transactionsFilter';
import { SalesTransactionsFull } from '../models/sales/salesTransactionsFull';
import { PaymentGatewayPost } from '../models/sales/paymentGatewayPost.dto';
import { ProjectImagesDto } from '../models/projects/projectImagesDto';
import { QrCodeSummaryDto } from '../models/qrcodes/qrCodeSummary.dto';
import { QrCodeHistoryItemDto } from '../models/qrcodes/qrCodeHistoryItem.dto';
import { DashboardCodes } from '../models/dashboard/dashboardCodes';
import { TemplateShares } from '../models/dashboard/templateShares';
import { ActivePhotographersDto } from '../models/dashboard/activePhotographers.dto';
import { ProjectPhotos } from '../models/dashboard/projectPhotos';
import { ChartObject } from '../models/dashboard/chartObject';
import { PaypalCreateDto } from '../models/paypal/paypalCreate.dto';
import { CreatePaymentDto } from '../models/paypal/createPayment.dto';
import { ExecutePaymentDto } from '../models/paypal/executePayment.dto';
import { StripeCreatePaymentDto } from '../models/stripe/stripeCreatePayment.dto';
import { StripeCreateResponseDto } from '../models/stripe/stripeCreateResponse.dto';
import { CreateStripeCustomerDto } from '../models/stripe/createStripeCustomer.dto';
import { DownloadCodesDto } from '../models/qrcodes/downloadCodes.dto';
import { DataCollectionDto } from '../models/templates/dataCollection.dto';
import { FileDownloadDto } from '../models/fileDownload.dto';
import { AllSubscriptionItemDto } from '../models/subscriptions/allSubscriptionItem.dto';
import { EntityStatus } from '../common/options/enums';
import { ForgotPasswordDto } from '../models/forgotPassword';
import { ResetRequestDto } from '../models/resetRequest.dto';
import { RemoveTemplateResponse } from '../models/projects/removeTemplateResponse';
import { SubscriptionAnalyticsDto } from '../models/subscriptions/subscriptionAnalytics.dto';
import { PaginationResponse } from '../models/pagination';
import { VerifyEmailResponse } from '../models/verifyEmailResponse';
import { AcceptTermsAndConditionsDto } from 'src/app/models/acceptTermsAndConditions.dto';
import { EditSubscriptionBucket } from 'src/app/models/subscriptions/editSubscriptionStorageBucket';

export interface IGetQuery {
  [key: string]: string | undefined;
}

const getQueryParams = (params: { [key: string]: string | undefined }) => {
  let param = '?';
  const keys = Object.keys(params);
  keys.forEach((key: string, index) => {
    if (!params[key]) return;
    param += `${key}=${params[key]}`;
    if (index !== keys.length - 1) {
      param += '&';
    }
  });
  return param;
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(async (config) => {
  const token = store.commonStore.token;

  // if (config.url && config.url.includes('refreshToken')) {
  config.withCredentials = true;
  // }

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const { data, status, config, headers } = error.response!;
    switch (status) {
      case 400:
        if (typeof data === 'string') {
          if (data.includes('jwt error')) {
            toast.error('Error with your access token, please login again');
            await store.userStore.logout();
            return;
          }

          if (!check(data)) toast.error(data);
          throw data;
        }
        if (
          config.method === 'get' &&
          (
            data.errors ?? {
              hasOwnProperty: () => {},
            }
          ).hasOwnProperty('id')
        ) {
          history.push('/not-found');
        }
        if (data.errors) {
          const modelStateError = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modelStateError.push(data.errors[key]);
            }
          }
          throw modelStateError.flat();
        }
        break;
      case 401:
        const token = store.commonStore.token;

        if (
          token &&
          !config.url.includes('refreshToken') &&
          !config.url.includes('login')
        ) {
          config._retry = true;
          const refreshedToken = await store.userStore.refreshToken();
          axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${refreshedToken}`;
          return axios(config);
        }
        if (
          status === 401 &&
          headers['www-authenticate']?.startsWith(
            'Bearer error="invalid_token"'
          )
        ) {
          store.userStore.logout();
          toast.error('Session expired - please login again');
        } else {
          store.commonStore.setToken(null);
          window.localStorage.removeItem('jwt');
          history.push('/login');
        }

        break;

      case 403:
        store.userStore.logout();
        toast.error('Session expired - please login again');
        break;
      case 404:
        history.push('/not-found');
        break;
      case 500:
        store.commonStore.setServerError(data);
        history.push('/server-error');
        break;
    }
    return Promise.reject(error);
  }
);
const responseBody = <T>(response: AxiosResponse<T>) => response.data;
const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
  del_body: <T>(url: string, data?: {}) =>
    axios.delete<T>(url, { data }).then(responseBody),
};

const Account = {
  current: () => requests.get<User>('/account'),
  login: (user: LoginDTO) => requests.post<User>('/account/login', user),
  logout: () => requests.post<any>('/account/logout', {}),
  register: (user: UserFormValues) =>
    requests.post<User>('/account/register', user),
  fbLogin: (accessToken: string) =>
    requests.post<User>(`/account/fbLogin?accesstoken=${accessToken}`, {}),
  refreshToken: () => requests.post<User>('/account/refreshToken', {}),
  verifyEmail: (token: string, email: string) =>
    requests.post<VerifyEmailResponse>(`/account/verifyEmail`, {
      token,
      email,
    }),
  resendEmailConfirm: (email: string) =>
    requests.post('/account/resendEmailConfirmationLink', { email }),
  forgotPassword: (dto: ForgotPasswordDto) =>
    requests.post<any>(`/account/ForgotPassword`, dto),
  resetPassword: (dto: ResetRequestDto) =>
    requests.post<any>(`/account/ResetPassword`, dto),
  acceptTermsAndConditions: (dto: AcceptTermsAndConditionsDto) =>
    requests.post<boolean>(`/account/terms`, dto),
};

const Templates = {
  uploadFile: (body: any) => {
    return axios.post<{ fileUrl: string }>('/fileupload/files/working/', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  createTemplate: (body: TemplateCreateDTO) =>
    requests.post<Template>('/templates/create', body),
  listByProject: (projectId: string) =>
    requests.get<Template[]>(
      `/templates/templategroups/${projectId}/templates`
    ),
  listBySubscription: (
    subscriptionID: string,
    queryParams?: { [key: string]: string }
  ) =>
    requests.get<Template[]>(
      `/templates/subscriptions/${subscriptionID}/templates${
        queryParams ? getQueryParams(queryParams) : ''
      }`
    ),
  getById: (id: number, queryParams?: { [key: string]: string }) =>
    requests.get<Template>(
      `/templates/by/id/${id}${queryParams ? getQueryParams(queryParams) : ''}`
    ),
  settings: (id: number) =>
    requests.get<TemplateSettingsDTO>(`/templates/settings/${id}`),
  delete: (code: string) => requests.del<any>(`/templates/${code}`),
  updateTemplateInfo: (body: TemplateInfoDTO) =>
    requests.put<boolean>(`/templates/updateinfo/${body.id}`, body),
  getImageSets: (id: number) =>
    requests.get<TemplateImageSetFull[]>(`/templateimagesets/${id}/imagesets`),
  createImageSet: (id: number) =>
    requests.post<TemplateImageSetFull>(
      `/templateimagesets/${id}/imagesets`,
      id
    ),
  deleteImageSet: (id: number) =>
    requests.del<any>(`/templateimagesets/imagesets/${id}`),
  updateImageSet: (id: number, body: TemplateImageSetPutDTO) =>
    requests.put<TemplateImageSetFull>(
      `/templateimagesets/imagesets/${id}`,
      body
    ),
  updateImages: (body: any) => requests.put<any>(`/templateimage/`, body),
  updateWebsite: (
    body: TemplateWebsite,
    ids: { [key: string]: string | undefined }
  ) =>
    requests.put<boolean>(
      `/templatewebsite/UpdateWebsite${getQueryParams(ids)}`,
      body
    ),
  getDataCollection: (id: number) =>
    requests.get<TemplateDataCollection>(
      `/templatedatacollection/${id}/datacollection`
    ),
  updateDataCollection: (
    body: TemplateDataCollection,
    ids: { [key: string]: string | undefined }
  ) =>
    requests.put<TemplateDataCollection>(
      `/templatedatacollection/UpdateDataCollection${getQueryParams(ids)}`,
      body
    ),
  startDataCollection: (id: number) =>
    requests.post<boolean>(
      `/templatedatacollection/${id}/datacollection/start`,
      {}
    ),
  stopDataCollection: (id: number) =>
    requests.post<boolean>(
      `/templatedatacollection/${id}/datacollection/stop`,
      {}
    ),
  popupResults: (id: number, startDate: string, endDate: string) =>
    requests.get<DataCollectionDto[]>(
      `/datacollections/${id}/${startDate}/${endDate}`
    ),
  exportDataCollection: (id: number, startDate: string, endDate: string) =>
    requests.get<FileDownloadDto>(
      `/datacollections/export/${id}/${startDate}/${endDate}`
    ),
  updateStatus: (id: number, status: string) =>
    requests.put<any>(`/templates/${id}/${status}`, {}),
};

const Currencies = {
  allowedCurrencies: (id: number) =>
    requests.get<{ subscriptionCurrencyDtos: TemplateCurrencyDTO[] }>(
      `/templates/currencies/allowed/${id}`
    ),
  subscriptionCurrencies: (id: number) =>
    requests.get<{ subscriptionCurrencyDtos: TemplateCurrencyDTO[] }>(
      `/subscriptions/currencies/${id}`
    ),
  updateSubscriptionCurrencies: (
    dto: { subscriptionCurrencyUpdateDtos: SubscriptionCurrencyUpdateDto[] },
    id: number
  ) => requests.put<boolean>(`/subscriptions/currencies/${id}`, dto),
};

const PaymentGateways = {
  list: (id: number) =>
    requests.get<PaymentGateway[]>(`/paymentgateway/subscription/${id}`),
};

const Subscriptions = {
  create: (dto: SubscriptionDto) => requests.post<any>(`/subscriptions/`, dto),
  fetch: (id: number, params?: { [key: string]: string | undefined }) =>
    requests.get<SubscriptionFullDto>(
      `/subscriptions/${id}${params ? getQueryParams(params) : ''}`
    ),
  getUsers: (id: number) =>
    requests.get<AppUser[]>(`/subscriptions/${id}/users?mode=full`),
  fetchCurrentUser: () =>
    requests.get<UserDetails>(`/subscriptions/GetCurrentUserAccount`),
  update: (id: number, dto: EditSubscriptionUser) =>
    requests.put<any>(`/subscriptions/${id}/CurrentUser`, dto),
  list: () =>
    requests.get<SubscriptionFullDto[]>(
      `/subscriptions?mode=full&includeUsers=false`
    ),
  changePackage: (id: number, stripeId?: string) =>
    requests.put<any>(`/subscriptions/EditAccountSubscriptionPage`, {
      SubscriptionPackageId: id,
      stripeId,
    }),
  requestSubscriptionPackageChange: (id: number, stripeId?: string) =>
    requests.put<any>(`/subscriptions/RequestAccountSubscriptionChange`, {
      SubscriptionPackageId: id,
      stripeId,
    }),
  listAll: () =>
    requests.get<AllSubscriptionItemDto[]>(`/subscriptions/GetAllAccounts`),
  deactivate: (id: number, status: EntityStatus) =>
    requests.put<boolean>(
      `/subscriptions/DeactivateAccount?SubscriptionId=${id}&StatusId=${status}`,
      {}
    ),
  analytics: (id: number) =>
    requests.get<SubscriptionAnalyticsDto>(`subscriptions/analytics/${id}`),
  updateStorageBucket: (id: number, data: EditSubscriptionBucket) =>
    requests.put<boolean>(`/subscriptions/UpsertStorageBucket/${id}`, data),
};

const Team = {
  deleteUser: (dto: DeleteUserDto) =>
    requests.put<boolean>(`/account/delete/user/${dto.userToDelete}`, dto),
  addMember: (dto: AddMemberDto) =>
    requests.post<any>(`/account/register`, dto),
  getMember: (subscriptionId: number, userId: number) =>
    requests.get<TeamMember>(`/subscriptions/${subscriptionId}/user/${userId}`),
  editMember: (
    subscriptionId: number,
    userId: number,
    dto: EditTeamMemberDto
  ) =>
    requests.put<TeamMember>(
      `/subscriptions/${subscriptionId}/user/${userId}`,
      dto
    ),
  update: (dto: EditSubscriptionUser) =>
    requests.put<any>(`/subscriptions/${dto.id}/CurrentUser`, dto),
};

const Projects = {
  create: (dto: ProjectPostDTO) =>
    requests.post<ProjectDTO>('/templategroups/create', dto),
  list: (subscriptionId: number) =>
    requests.get<ProjectDTO[]>(
      `/templategroups/subscription/${subscriptionId}/templategroups?mode=full&filter=active,deactive`
    ),
  find: (id: string) =>
    requests.get<ProjectDTO>(`/templategroups/${id}?mode=full`),
  update: (id: string, dto: ProjectPutDTO) =>
    requests.put<boolean>(`/templategroups/update/${id}`, dto),
  add_templates: (id: string, dto: ProjectAddTemplate) =>
    requests.put<boolean>(`/templategroups/${id}/add/templates`, dto),
  delete_templates: (id: string, dto: ProjectAddTemplate) =>
    requests.del_body<RemoveTemplateResponse>(
      `/templategroups/${id}/delete/templates`,
      dto
    ),
  get_templates: (id: string) =>
    requests.get<Template[]>(`/templategroups/${id}/templates?mode=full`),
  addUsers: (id: number, users: { userIds: number[] }) =>
    requests.put<boolean>(`/templategroups/${id}/add/users`, users),
  removeUsers: (id: number, users: { userIds: number[] }) =>
    requests.put<boolean>(`/templategroups/${id}/remove/users`, users),
  getUsers: (id: number) =>
    requests.get<ProjectUser[]>(`/templategroups/${id}/get/users`),
  archive: (id: number) =>
    requests.del<boolean>(`/templategroups/archive/${id}`),
  projectPhotos: (params: { [key: string]: string | undefined }) =>
    requests.get<PaginationResponse<ProjectImagesDto>>(
      `/templategroups/GetTemplateGroupImage${getQueryParams(params)}`
    ),
};
const Photos = {
  linkPhotosToNewQRCode: (snaptImageIds: number[], albumCode: string) =>
    requests.post<boolean>(
      `/photo/linkSnaptImagesToCode/${albumCode}`,
      snaptImageIds
    ),
  unwatermarkImages: (snaptImageIds: number[], albumCode: string) => {
    return requests.post<boolean>(`/photo/unwatermarkImages`, {
      snaptImageIds,
      albumCode,
    });
  },
};

const Sales = {
  transactions: (filter: TransactionsFilter) =>
    requests.post<SalesTransactionsFull>(`/sales/transactions`, filter),
  createGateway: (body: PaymentGatewayPost) =>
    requests.post<PaymentGateway>(`/paymentgateway/create`, body),
  listGateways: (id: number) =>
    requests.get<PaymentGateway[]>(`/paymentgateway/subscription/${id}`),
  deleteGateway: (id: number) => requests.del<any>(`/paymentgateway/${id}`),
  fetchGateway: (id: number) =>
    requests.get<PaymentGateway>(`/paymentgateway/${id}`),
  editGateway: (id: number, body: PaymentGatewayPost) =>
    requests.put<PaymentGateway>(`/paymentgateway/update/${id}`, body),
  allTransactions: (filter: TransactionsFilter) =>
    requests.post<SalesTransactionsFull>(`/sales/transactionsForSys`, filter),
};

const QRCodes = {
  summary: (query: { [key: string]: string | undefined }) =>
    requests.get<QrCodeSummaryDto>(
      `/albumcodes/QrfirstPage/Subscription${getQueryParams(query)}`
    ),
  download: (id: number) =>
    requests.get<DownloadCodesDto>(`/albumcodes/download/${id}`),
  history: (params: { [key: string]: string | undefined }) =>
    requests.get<QrCodeHistoryItemDto[]>(
      `/albumcodes/QrHistoryPage${getQueryParams(params)}`
    ),
  generate: (params: { [key: string]: string | undefined }) =>
    requests.get<boolean>(
      `${
        process.env.REACT_APP_RABBIT_API
      }/api/albumcodes/request${getQueryParams(params)}`
    ),
  test: (quert: { [key: string]: string | undefined }) =>
    requests.get<QrCodeSummaryDto>(
      `/albumcodes/QrfirstPage/Subscription?subscriptionId=24`
    ),
};

const Dashboard = {
  qrSummary: (id: number) =>
    requests.get<DashboardCodes>(`/dashboard/qrCodeSummary/${id}`),
  socialShares: (id: number, startDate: string, endDate: string) =>
    requests.get<TemplateShares>(
      `/dashboard/Photosummary/${id}/${startDate}/${endDate}`
    ),
  activePhotographers: (id: number, startDate: string, endDate: string) =>
    requests.get<ActivePhotographersDto>(
      `/dashboard/PhotographerSummary/${id}/${startDate}/${endDate}`
    ),
  activeProjects: (id: number, startDate: string, endDate: string) =>
    requests.get<ProjectPhotos[]>(
      `/dashboard/PhotOnlineosummary/${id}/${startDate}/${endDate}`
    ),
  activeTemplates: (id: number, startDate: string, endDate: string) =>
    requests.get<ChartObject>(
      `stats/subscription/${id}/most-active-templates/${startDate}/${endDate}`
    ),
};

const Paypal = {
  create: (dto: CreatePaymentDto) =>
    requests.post<PaypalCreateDto>(`paypal/CreatePayment`, dto),
  execute: (dto: ExecutePaymentDto) =>
    requests.post<any>(`paypal/ExecutePayment`, dto),
};

const Stripe = {
  create: (dto: StripeCreatePaymentDto) =>
    requests.post<StripeCreateResponseDto>(`/stripe/CreateStripePayment`, dto),
  execute: (quantity: number) =>
    requests.post<any>(`/stripe/PassSuccessOrFailValue/1/${quantity}`, {}),
  createCustomer: (dto: CreateStripeCustomerDto) =>
    requests.post<any>(`/stripe/subscription/create`, dto),
  getIntent: (id: string) =>
    requests.get<any>(`/stripe/GetStripePayment/${id}`),
  cancelSub: (id: number) =>
    requests.put<any>(`/stripe/subscription/cancel`, { subscriptionId: id }),
};

const Paygate = {
  execute: (quantity: number) =>
    requests.post<boolean>(`/paygate/execute`, { count: quantity }),
};

const agent = {
  Account,
  Templates,
  Currencies,
  PaymentGateways,
  Subscriptions,
  Projects,
  Team,
  Sales,
  QRCodes,
  Dashboard,
  Paypal,
  Stripe,
  Paygate,
  Photos,
};

export default agent;
