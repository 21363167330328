import React, { useEffect } from 'react';
import { Button, Card } from 'antd';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import CurrencyItem from './CurrencyItem';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { getActiveSubscriptionId } from '../../../app/common/util/common';

function SalesCurrencies() {
  const { currencyStore, userStore } = useStore();
  const { user } = userStore;
  const {
    loading,
    subscriptionCurrencies,
    submitting,

    fetchSubscriptionCurrencies,
    updateCurrencies,
  } = currencyStore;
  const activeSubscriptionId = getActiveSubscriptionId();

  useEffect(() => {
    if (!user || !activeSubscriptionId) return;

    fetchSubscriptionCurrencies(activeSubscriptionId);
  }, [fetchSubscriptionCurrencies, activeSubscriptionId]);

  function save() {
    if (!user || subscriptionCurrencies.length === 0 || !activeSubscriptionId)
      return;

    updateCurrencies(
      {
        subscriptionCurrencyUpdateDtos: subscriptionCurrencies,
      },
      activeSubscriptionId
    );
  }

  if (loading) return <LoadingComponent content="Loading currencies..." />;

  return (
    <>
      <div className="sales-currency">
        <div style={{ display: 'flex' }}>
          <h3 className="tab-header">Currencies</h3>
          <Button loading={submitting} type="primary" onClick={save}>
            Save
          </Button>
        </div>

        <Card>
          <div className="sales-currency-list-header">
            <p className="list-header-allow">Allow</p>
            <p className="list-header-name">Name</p>
            <p className="list-header-single">Single Image Cost</p>
            <p className="list-header-all">All Images Cost</p>
            <p className="list-header-default">Default</p>
          </div>

          {subscriptionCurrencies.map((c) => (
            <CurrencyItem key={c.currencyId} currency={c} />
          ))}
        </Card>
      </div>
    </>
  );
}

export default observer(SalesCurrencies);
