import { Row, Col, Tooltip, Button } from 'antd';
import React from 'react';
import TemplateEditorContent from './TemplateEditorContent';
import TemplateEditorMenus from './TemplateEditorMenus';
import { useStore } from '../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import {
  getActiveSubscriptionId,
  navigate,
} from '../../../app/common/util/common';

function TemplateEditor() {
  const { templatesStore, fabricStore, userStore } = useStore();

  const {
    selectedTemplate,
    submitting,
    disableSubmitSettings,
    disableSubmitCommerce,
    disableSubmitLayers,
    updateTemplate,
    clearTemplate,
    selectedImageSet,
  } = templatesStore;
  const activeSubscriptionId = getActiveSubscriptionId();
  const { removeCanvas } = fabricStore;

  const { user } = userStore;

  function goBack() {
    navigate(`/templates`);
    clearTemplate();
    removeCanvas();
  }

  async function saveTemplate() {
    if (!user || !activeSubscriptionId) return;
    await updateTemplate(activeSubscriptionId);
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <h3 className={'tab-header'} style={{ marginRight: 'auto' }}>
          Editor
        </h3>

        <Tooltip
          overlayInnerStyle={{ top: '85px' }}
          placement="bottomLeft"
          title={
            selectedTemplate!.isGreenScreen! &&
            (selectedImageSet!.templateImages[0].backgroundUrl === '' ||
              selectedImageSet!.templateImages[1].backgroundUrl === '')
              ? 'Please ensure you have uploaded a Portrait AND Landscape image for background!'
              : undefined
          }>
          <Button
            type="primary"
            onClick={saveTemplate}
            loading={submitting}
            disabled={
              disableSubmitSettings ||
              disableSubmitCommerce ||
              disableSubmitLayers
            }>
            Save
          </Button>
        </Tooltip>
      </div>
      <Row className="template-editor">
        <Col span={18}>
          <TemplateEditorContent />
        </Col>

        <Col span={6}>
          <TemplateEditorMenus />
        </Col>
      </Row>
    </>
  );
}

export default observer(TemplateEditor);
