import { Button, Layout, Space, Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { useStore } from '../../app/stores/store';
import NotFound from '../errors/NotFound';
import { history } from '../..';
import TemplateEditor from './components/TemplateEditor';
import TemplateWebsite from './components/TemplateWebsite/TemplateWebsite';
import TemplatePopup from './components/TemplatePopup/TemplatePopup';
import PopupResults from './components/TemplatePopup/PopupResults';
import { yyyymmdd } from '../../app/common/util/date';
import useQuery from '../../app/common/util/hooks';
import {
  getActiveSubscriptionId,
  navigate,
} from '../../app/common/util/common';

function ViewTemplate() {
  const {
    templatesStore,
    fabricStore,
    userStore,
    currencyStore,
    salesStore,
    projectStore,
    subscriptionsStore,
  } = useStore();

  const {
    selectedTemplate,
    loading,
    loadingImageSets,
    fetchTemplate,
    clearTemplate,
    fetchCollectionResults,
  } = templatesStore;

  const { fetchAllowedCurrencies, loading: currencyLoading } = currencyStore;

  const { fetchPaymentGateways, loadingGateways } = salesStore;

  const { setReadonlySettings } = projectStore;

  const { removeCanvas } = fabricStore;

  const { user } = userStore;

  const { id } = useParams<{ id: string }>();
  const [activeTab, setActiveTab] = useState('1');
  const query = useQuery();
  const activeSubscriptionId = getActiveSubscriptionId();

  useEffect(() => {
    if (!user || !activeSubscriptionId) return;

    if (!id) {
      navigate(`/templates`);
      return;
    }
    // clearSelectedLayer();
    fetchTemplate(+id);

    if (
      user.isSysAdmin
        ? subscriptionsStore.selectedSubscription?.subscriptionPackageId !== 1
        : user.subscriptionPackageId !== 1
    ) {
      fetchAllowedCurrencies(activeSubscriptionId);
      fetchPaymentGateways(activeSubscriptionId);
      fetchCollectionResults(+id, '20000101', yyyymmdd(new Date()));
    }

    setReadonlySettings(false);

    const tab = query.get('tab');
    if (tab) {
      if (['1', '2', '3'].includes(tab)) {
        setActiveTab(tab);
      }
    }
  }, [
    fetchTemplate,
    id,
    fetchAllowedCurrencies,
    fetchPaymentGateways,
    activeSubscriptionId,
  ]);

  function goBack() {
    history.goBack();
    clearTemplate();
    removeCanvas();
  }

  if (loading || loadingImageSets || loadingGateways || currencyLoading)
    return <LoadingComponent content="Loading template information ..." />;

  if (!selectedTemplate) return <NotFound />;
  const { Header, Content } = Layout;

  const { TabPane } = Tabs;
  return (
    <>
      <Layout className="view-template">
        <Header className="view-template-header">
          <h2>{selectedTemplate.name}</h2>
          <Space className="view-template-buttons">
            <Button onClick={() => goBack()} className="yellow-ant-btn">
              Close
            </Button>
          </Space>
        </Header>

        <Content>
          <Tabs
            className="view-project-tabs"
            defaultActiveKey="1"
            onChange={(e) => setActiveTab(e)}
            activeKey={activeTab}>
            <TabPane key="1" tab="Editor">
              <TemplateEditor />
            </TabPane>

            <TabPane key="2" tab="Website">
              <TemplateWebsite />
            </TabPane>

            {(user ?? { subscriptionPackageId: 1 }).subscriptionPackageId !==
              1 && (
              <>
                <TabPane key="3" tab="Popup">
                  <TemplatePopup />
                </TabPane>

                <TabPane key="4" tab="Popup Results">
                  <PopupResults />
                </TabPane>
              </>
            )}
          </Tabs>
        </Content>
      </Layout>
    </>
  );
}

export default observer(ViewTemplate);
