import { history } from '../../..';
import { store } from '../../stores/store';

export const getUserNameInitials = (name?: string, maxLength: number = -1) => {
  if (!name) return '';
  const names = name.split(' ');
  const initials = names.map((n) => n[0]).join('');
  return initials.length > maxLength ? initials.slice(0, maxLength) : initials;
};

/**
 * A simple wrapper around history.push that prepends a baseUrl set in the navigationStore.
 * @param url - string to navigate to
 */
export const navigate = (url: string) => {
  const baseUrl = store.navigationStore.baseUrl;
  history.push(`${baseUrl}${url}`);
};

export function getSubscriptionIdFromUrl(url?: string): number | null {
  if (!url) return null;
  const regex = /subscription\/(\d+)/;
  const match = url.match(regex);
  if (match && Number(match?.[1])) {
    return parseInt(match[1], 10);
  }
  return null;
}

export const getActiveSubscriptionId = () =>
  getSubscriptionIdFromUrl(window?.location?.pathname) ??
  store?.subscriptionsStore.selectedSubscription?.id ??
  store?.userStore.user?.subscriptionId;

export const getActiveSubscriptionName = () => {
  return store.userStore.user?.isSysAdmin
    ? store.subscriptionsStore.selectedSubscription?.companyName
    : store.accountStore?.selectedUserDetails?.companyName || '';
};
