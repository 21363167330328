import React, { useState } from 'react';
import { Button, Table, Tooltip } from 'antd';
import { useStore } from '../../../app/stores/store';
import EmptyList from '../../../app/layout/EmptyList';
import { observer } from 'mobx-react-lite';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import {
  SaleStatuses,
  SaleTypes,
} from '../../../app/common/options/selectOptions';
import { SalesInfoDto } from '../../../app/models/sales/saleInfoDto';
import { toXlsx } from '../../../app/common/util/excelExport';
import { ColumnsType } from 'antd/es/table/interface';

function SalesTransactions() {
  const { salesStore } = useStore();

  const { transactions, loadingTransactions } = salesStore;

  const columns: ColumnsType<any> = [
    {
      title: 'Sale Date',
      dataIndex: 'saleDateTime',
      key: 'saleDateTime',
      defaultSortOrder: 'descend',
      sorter: (a: Date, b: Date) => (new Date(a) > new Date(b) ? 1 : -1),
      render: (dateTime: Date, record: SalesInfoDto) => {
        let showDate = dateTime;
        const zeroDate = new Date('0001-01-01').toISOString().split('.')[0];
        if (zeroDate === dateTime.toString()) showDate = record.createDateTime;

        return (
          <div>
            {new Date(showDate).toLocaleDateString('en-ZA', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          </div>
        );
      },
    },
    {
      title: 'Currency',
      dataIndex: 'currencyCode',
      key: 'currencyCode',
    },
    {
      title: 'Total Cost',
      dataIndex: 'totalCost',
      key: 'totalCost',
    },
    {
      title: 'Gallery Code',
      dataIndex: 'galleryCode',
      key: 'galleryCode',
    },
    {
      title: 'Sale Type',
      dataIndex: 'saleType',
      key: 'saleType',
      render: (saleType: number) => (
        <div>
          {
            (SaleTypes.find((t) => t.value === saleType) ?? { display: '' })
              .display
          }
        </div>
      ),
    },
    {
      title: 'Sale Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: number, record: SalesInfoDto) => (
        <Tooltip title={record.statusMessage}>
          {
            (SaleStatuses.find((s) => s.value === status) ?? { display: '' })
              .display
          }
        </Tooltip>
      ),
    },
  ];
  const [loadingExport, setLoadingExport] = useState<boolean>(false);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {},
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const downloadReport = () => {
    if (!transactions) return;

    try {
      setLoadingExport(true);
      const date = new Date().toLocaleDateString('en-Za').replace(/\//g, '');
      const fileName = `Sales-${date}`;

      const zeroDate = new Date('0001-01-01').toISOString().split('.')[0];

      const data = transactions.transactionData.map(
        (transaction: SalesInfoDto) => ({
          'Sale Date': new Date(
            zeroDate === transaction.saleDateTime.toString()
              ? transaction.createDateTime
              : transaction.saleDateTime
          ).toLocaleDateString('en-ZA', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          }),
          Currency: transaction.currencyCode,
          'Total Cost': transaction.totalCost,
          'Gallery Code': transaction.galleryCode,
          'Sale Type': (
            SaleTypes.find((t) => t.value === transaction.saleType) ?? {
              display: '',
            }
          ).display,
          'Sale Status': (
            SaleStatuses.find((s) => s.value === transaction.status) ?? {
              display: '',
            }
          ).display,
        })
      );

      toXlsx(data, fileName);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingExport(false);
    }
  };

  return (
    <>
      <div className="transactions-table">
        <div className="transactions-table-header">
          <h3>Transactions</h3>

          <Button
            disabled={
              !transactions ||
              transactions.transactionData.length === 0 ||
              loadingExport
            }
            loading={loadingExport}
            onClick={downloadReport}
            type="primary">
            Export Report
          </Button>
        </div>

        {loadingTransactions ? (
          <LoadingComponent content="Loading transactions... " />
        ) : !transactions || transactions.transactionData.length === 0 ? (
          <EmptyList
            customImage="/assets/images/no-transactions.svg"
            message="No transactions for this filter."
            hideButton={true}
            buttonAction={() => {}}
            buttonText=""
          />
        ) : (
          <Table
            rowSelection={rowSelection}
            className="live-name"
            dataSource={transactions.transactionData}
            columns={columns}
            rowKey={'transactionId'}
          />
        )}
      </div>
    </>
  );
}

export default observer(SalesTransactions);
