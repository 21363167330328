import { Button, Input, Layout, Space, Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import TemplatesList from './components/TemplatesList';
import { useStore } from '../../app/stores/store';
import TemplatesListArchived from './components/TemplatesListArchived';
import { getActiveSubscriptionId } from '../../app/common/util/common';

function Templates() {
  const { templatesStore, userStore, fabricStore } = useStore();

  const { clearCanvas } = fabricStore;

  const { user } = userStore;

  const { getTemplatesBySubscription, clearTemplate } = templatesStore;
  const activeSubscriptionId = getActiveSubscriptionId();

  const [searchValue, setSearchValue] = useState('');
  const [inputValue, setInputValue] = useState('');

  function searchList() {
    setSearchValue(inputValue);
  }

  function enterPress(e: any) {
    setSearchValue(e.target.value);
  }

  function clear(e: any) {
    if (e.target.value.trim() === '') setSearchValue('');
  }

  useEffect(() => {
    if (!user || !activeSubscriptionId) return;
    clearCanvas();
    clearTemplate();
    getTemplatesBySubscription(activeSubscriptionId.toString());
  }, [getTemplatesBySubscription, activeSubscriptionId]);

  const search = (
    <>
      <Space size={10} style={{ marginRight: '30px' }}>
        <Input
          onPressEnter={(e) => enterPress(e)}
          onBlur={(e) => setInputValue(e.target.value)}
          allowClear
          onChange={clear}
        />
        <Button type="primary" onClick={searchList}>
          Search
        </Button>
      </Space>
    </>
  );

  const { Header, Content } = Layout;
  const { TabPane } = Tabs;
  return (
    <>
      <Layout className="view-project">
        <Header className="view-project-header">
          <div>
            <h2>Templates</h2>
          </div>
        </Header>

        <Content>
          <Tabs
            defaultActiveKey="1"
            className="view-project-tabs"
            tabBarExtraContent={search}>
            <TabPane key="1" tab="All">
              <TemplatesList search={searchValue} type="all" />
            </TabPane>

            {user!.subscriptionPackageId !== 1 && (
              <TabPane key="2" tab="No Background">
                <TemplatesList search={searchValue} type="green" />
              </TabPane>
            )}

            <TabPane key="3" tab="Standard">
              <TemplatesList search={searchValue} type="standard" />
            </TabPane>

            <TabPane key="4" tab="Archived">
              <TemplatesListArchived search={searchValue} />
            </TabPane>
          </Tabs>
        </Content>
      </Layout>
    </>
  );
}

export default observer(Templates);
