import { makeAutoObservable } from 'mobx';

export class AddPaymentStore {
  selectedPaymentMethod: any | null = {
    id: 1,
    paypal: 'Paypal',
    paygate: 'Paygate',
  };

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedPaymentMethod = (paymentMethod: any) => {
    this.selectedPaymentMethod = paymentMethod;
  };
}
