import agent from '../api/Agent';
import { makeAutoObservable } from 'mobx';
import { UserDetails } from '../models/subscriptions/userDetails';
import { EditSubscriptionUser } from '../models/subscriptions/editSubscriptionUser';
import { ForgotPasswordDto } from '../models/forgotPassword';
import { ResetRequestDto } from '../models/resetRequest.dto';

export class AccountStore {
  submitting: boolean = false;
  loading: boolean = false;
  selectedUserDetails: UserDetails | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setSubmitting = (value: boolean) => {
    this.submitting = value;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setSelectedUserDetails = (value: UserDetails | null) => {
    this.selectedUserDetails = value;
  };

  fetchDetails = async () => {
    try {
      this.setLoading(true);
      const result = await agent.Subscriptions.fetchCurrentUser();
      this.setSelectedUserDetails(result);
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false);
    }
  };

  updateDetails = async (id: number, dto: EditSubscriptionUser) => {
    try {
      this.setSubmitting(true);
      await agent.Subscriptions.update(id, dto);
    } catch (error) {
      throw error;
    } finally {
      this.setSubmitting(false);
    }
  };

  requestReset = async (dto: ForgotPasswordDto) => {
    try {
      this.setSubmitting(true);

      await agent.Account.forgotPassword(dto);
    } catch (error) {
      throw error;
    } finally {
      this.setSubmitting(false);
    }
  };

  resetPassword = async (dto: ResetRequestDto) => {
    try {
      this.setSubmitting(true);

      await agent.Account.resetPassword(dto);
    } catch (error) {
      throw error;
    } finally {
      this.setSubmitting(false);
    }
  };

  acceptTermsAndConditions = async (termsVersion: string) => {
    try {
      return await agent.Account.acceptTermsAndConditions({
        accepted: true,
        termsVersion,
      });
    } catch (error) {
      console.error(error);
      return false;
    }
  };
}
